<template>
  <r-modal
    class="createentitymapmodal"
    :title="'Create entity map'"
    :max-content-height="600"
    @close="onClickCancel"
  >
    <template v-slot:content>
      <create-edit-entity-map
        :source="source"
        :entity-map-id="'add'"
        :redirect-after-save="false"
        :show-header-page="false"
        @cancel="onClickCancel"
        @created-new-entity-map="onNewEntityMapCreated"
      />
    </template>

    <template v-slot:customActions>
      <div />
    </template>
  </r-modal>
</template>

<script>
import RModal from '@/components/library/organisms/RModal'
import CreateEditEntityMap from '@/components/app/tools/CreateEditEntityMap'

export default {
  name: 'SaveDashboardModal',
  components: {
    CreateEditEntityMap,
    RModal,
  },
  props: {
    source: {
      type: String,
    },
  },
  methods: {
    async onNewEntityMapCreated({ value }) {
      this.$emit('created-new-entity-map', { value })
      this.$emit('close')
    },
    onClickCancel() {
      this.$emit('close')
    },
  },
}
</script>
