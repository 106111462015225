<template>
  <div class="createeditentitymap py-8">
    <r-page-header
      v-if="showHeaderPage"
      :label="isNewMap ? 'Create entity map' : 'Edit entity map'"
      class="mb-4"
      :previousroute="BACK_ROUTE"
    >
      <template v-slot:end>
        <r-button
          v-if="!isNewMap"
          class="delete-button mr-2"
          label="Delete entity map"
          :disabled="fetching"
          outlined
          @click="onClickDeleteMap"
        />
        <r-button
          class="save--button"
          :label="isNewMap ? 'Create entity map' : 'Save entity map'"
          :loading="fetching"
          @click="onClickSave"
        />
      </template>
    </r-page-header>

    <r-error-message
      :key="`createeditentitymap--errormessage-${validationCount}`"
      :errors="[errors]"
    />

    <r-text-field
      ref="entityMapName"
      class="entity-map-name--input mb-4 mt-8"
      label="Entity map name"
      placeholder="Entity map name"
      :rules="[rules.required]"
      :value="name"
      @change="onChangeMapName"
    />

    <template v-if="!isNewMap && entityMapDashboards.length">
      <label>Dashboards it's applied to</label>
      <v-row class="createeditentitymap--dashboards ma-0 mt-4 mb-4">
        <r-dashboard-type-card
          v-for="dashboard in entityMapDashboards"
          :key="`dashboard-type-card-${dashboard._id}`"
          :type="dashboard.type"
          :label="dashboard.name"
        />
      </v-row>
    </template>

    <label>Ignore list</label>
    <r-text-chips
      :disabled="addIgnoreListDisabled"
      class="px-1 mb-12"
      :chips="ignore"
      @add-chip="onAddIgnore"
      @remove-chip="onRemoveIgnore"
    />

    <edit-entity-group
      v-for="group in groups"
      ref="edit-entity-group"
      :key="`edit-entity-group-${group._id}`"
      :name="group.name"
      :entities="group.entities"
      :editing="group.editing"
      :groups="groups"
      class="mt-4"
      @change:name="({ value }) => onChangeGroupName(group._id, value)"
      @change:entities="({ value }) => onChangeGroupEntities(group._id, value)"
      @delete="onDeleteGroup(group._id)"
    />

    <div class="d-flex justify-end mt-8">
      <v-tooltip
        :disabled="!addEntityGroupDisabled"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <r-button
            :attrs="attrs"
            :on="on"
            :style="addGroupButtonDisabledStyle"
            label="+ Add entity group"
            outlined
            @click="onClickAddGroup"
          />
        </template>
        <span>
          {{ $t('entityMaps.maxGroupPerMap', {limit: ENTITYMAPS_LIMITS.GROUPS_PER_MAP} ) }}
        </span>
      </v-tooltip>
    </div>

    <div
      v-if="!showHeaderPage"
      class="d-flex mt-3 align-center justify-end"
    >
      <r-button
        class="cancel--button mr-2"
        :outlined="true"
        :label="'Cancel'"
        :disabled="fetching"
        @click="onClickCancel"
      />
      <r-button
        class="save--button"
        :label="isNewMap ? 'Create entity map' : 'Save entity map'"
        :loading="fetching"
        @click="onClickSave"
      />
    </div>

    <r-modal
      v-if="confirmDelete"
      :title="$t('entityMaps.deleteEntityMap.title')"
      :close-action-label="$t('global.actions.cancel')"
      :action-label="$t('deleteConfirmation.delete')"
      :fetching="fetching"
      @submit="onDelete"
      @close="confirmDelete = false"
    >
      <template v-slot:content>
        <r-error-message :errors="[fetchError]" />

        <div class="d-flex justify-center align-center">
          {{ $t('entityMaps.deleteEntityMap.text') }}
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RButton from '@/components/library/atoms/RButton'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RTextField from '@/components/library/molecules/RTextField'
import RTextChips from '@/components/library/molecules/RTextChips'
import EditEntityGroup from '@/components/app/tools/EditEntityGroup'
import RDashboardTypeCard from '@/components/app/dashboards/common/RDashboardTypeCard'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RModal from '@/components/library/organisms/RModal'
import { ENTITYMAPS_LIMITS } from '@/utils/constants'
import { track } from '@/tracking'

export default {
  name: 'CreateEditEntityMap',
  components: {
    RButton,
    RPageHeader,
    RTextField,
    RTextChips,
    EditEntityGroup,
    RDashboardTypeCard,
    RErrorMessage,
    RModal,
  },
  props: {
    entityMapId: {
      type: String,
      required: true,
    },
    redirectAfterSave: {
      type: Boolean,
      default: true,
    },
    showHeaderPage: {
      type: Boolean,
      default: true,
    },
    source: {
      type: String,
      default: 'tools page',
    },
  },
  data: () => ({
    name: '',
    ignore: [],
    groups: [],
    confirmDelete: false,
    rules: {
      required: (v) => (v?.length > 0) || 'You must supply an entity map name.',
    },
    validationErrors: [],
    validationCount: 0,
    BACK_ROUTE: '/tools',
    ENTITYMAPS_LIMITS,
  }),
  computed: {
    ...mapGetters('entityMaps', [
      'entityMapName',
      'entityMapIgnores',
      'entityMapGroups',
      'entityMapDashboards',
      'fetching',
      'fetchError',
      'createdEntityMap',
    ]),
    allMapGroups() {
      return this.entityMapGroups()
    },
    isNewMap() {
      return this.$props.entityMapId === 'add'
    },
    errors() {
      return this.fetchError.concat(this.validationErrors)
    },
    addEntityGroupDisabled() {
      return this.$data.groups.length >= ENTITYMAPS_LIMITS.GROUPS_PER_MAP
    },
    addIgnoreListDisabled() {
      return this.$data.ignore.length >= ENTITYMAPS_LIMITS.IGNORES_PER_MAP
    },
    addGroupButtonDisabledStyle() {
      if (this.addEntityGroupDisabled) {
        return {
          'background-color': 'rgba(0, 0, 0, 0.12)',
          color: 'rgba(0,0,0,.26) !important',
        }
      }
      return {}
    },
  },
  watch: {
    entityMapId: {
      handler() {
        if (this.isNewMap) {
          return
        }
        const { entityMapId } = this.$props
        this.fetchEntityMap({ entityMapId })
        this.$data.name = this.entityMapName
      },
      immediate: true,
    },
    entityMapName() {
      this.$data.name = this.entityMapName
    },
    entityMapIgnores() {
      this.$data.ignore = [...this.entityMapIgnores]
    },
    allMapGroups() {
      this.$data.groups = [...this.entityMapGroups()]
    },
  },
  mounted() {
    if (this.isNewMap) {
      this.onClickAddGroup()
    }
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapActions('entityMaps', [
      'fetchEntityMap',
      'createEntityMap',
      'editEntityMap',
      'resetState',
      'deleteEntityMap',
    ]),
    onChangeMapName({ value }) {
      this.$data.name = value
    },
    onChangeGroupName(groupId, name) {
      const group = this.$data.groups.find(({ _id }) => _id === groupId)
      if (group) {
        group.name = name
      }
    },
    onChangeGroupEntities(groupId, entities) {
      const group = this.$data.groups.find(({ _id }) => _id === groupId)
      if (group) {
        group.entities = entities
      }
    },
    onAddIgnore({ value }) {
      this.$data.ignore = [...new Set(
        this.$data.ignore.concat(value),
      )]
    },
    onRemoveIgnore({ value }) {
      this.$data.ignore = this.$data.ignore.filter((v) => v !== value)
    },
    onClickAddGroup() {
      if (this.addEntityGroupDisabled) {
        return
      }
      this.$data.groups.push({
        _id: `new-group-${this.$data.groups.length}`,
        name: '',
        entities: [],
        editing: true,
      })
    },
    onDeleteGroup(groupId) {
      this.$data.groups = this.$data.groups.filter(({ _id }) => _id !== groupId)
    },
    validateAll() {
      const entityGroups = this.$refs['edit-entity-group'] || []
      const valid = entityGroups.map((vm) => vm.validate())
      this.$data.validationErrors = []
      this.$data.validationCount += 1
      if (!this.$data.name) {
        this.$data.validationErrors.push(this.$data.rules.required(false))
        valid.push(false)
      }
      return valid.every((v) => v)
    },
    async onClickSave() {
      if (!this.validateAll()) {
        return
      }

      const { entityMapId, source } = this.$props
      const { name, groups, ignore } = this.$data

      if (this.isNewMap) {
        await this.createEntityMap({ name, groups, ignore })
      } else {
        await this.editEntityMap({
          entityMapId, name, groups, ignore,
        })
      }
      if (!this.fetchError) {
        if (this.isNewMap) {
          track('create entity map page loaded - create entity map clicked', { source })
          const value = this.createdEntityMap
          this.$emit('created-new-entity-map', { value })
        }

        if (this.redirectAfterSave) {
          this.$router.push(this.$data.BACK_ROUTE)
        }
      }
    },
    onClickDeleteMap() {
      this.$data.confirmDelete = true
    },
    async onDelete() {
      const { entityMapId } = this.$props
      await this.deleteEntityMap({ entityMapId })
      if (!this.fetchError) {
        this.$data.confirmDelete = false
        this.$router.push(this.$data.BACK_ROUTE)
      }
    },
    onClickCancel() {
      return this.$emit('cancel')
    },
  },
}
</script>

<style scoped>
.createeditentitymap {
  max-width: 1024px;
  margin: 0 auto;
}
.createeditentitymap--dashboards {
  overflow: hidden;
  padding: 5px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1rem;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
/deep/ .rdtypecard {
  scroll-snap-align: center;
  margin-bottom: 20px;
}
/deep/ .rtextchips {
  border: 1px solid var(--r-border-color);
  border-radius: var(--r-border-radius);
}

@media all and (max-width: 1120px) {
  .createeditentitymap {
    padding-right: 32px;
    padding-left: 32px;
  }
}
</style>
