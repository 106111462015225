<template>
  <r-modal
    class="savedashboardasmodal"
    :title="$t('dashboards.saveAs.title')"
    :close-action-label="$t('global.actions.cancel')"
    :action-label="$t('dashboards.createDashboard.createDashboardBtn')"
    :fetching="fetchingSaveDashboardAs"
    :error-message="saveDashboardAsError"
    @close="onClose"
    @submit="onClickSave"
  >
    <template v-slot:content>
      <r-text-field
        :value="name"
        :label="$t('dashboards.createDashboard.name')"
        placeholder="Samsung S10"
        class="mb-2"
        @change="onChangeName"
      />
      <label>Add to project</label>
      <project-select
        @change="onChangeProject"
      />
    </template>
  </r-modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import RTextField from '@/components/library/molecules/RTextField'
import RModal from '@/components/library/organisms/RModal'
import ProjectSelect from '@/components/app/data/ProjectSelect'

export default {
  name: 'SaveDashboardAsModal',
  components: {
    RTextField,
    RModal,
    ProjectSelect,
  },
  data: () => ({
    name: '',
    projectId: null,
  }),
  computed: {
    ...mapState('dashboards', [
      'fetchingSaveDashboardAs',
      'saveDashboardAsError',
      'duplicatedDashboardId',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
  },
  mounted() {
    this.fetchProjects()
    this.projectId = this.selectedProjectId
  },
  methods: {
    ...mapActions('dashboards', [
      'saveDashboardAs',
      'resetSaveDashboardAsState',
    ]),
    ...mapActions('projects', [
      'fetchProjects',
    ]),
    async onClickSave() {
      const { name, projectId } = this.$data
      if (projectId) {
        await this.saveDashboardAs({ name, projectId })
      } else {
        await this.saveDashboardAs({ name })
      }
      if (!this.saveDashboardAsError && this.duplicatedDashboardId) {
        await this.$router.push(`/dashboards/${this.duplicatedDashboardId}`)
        this.$router.go(1)
        this.$emit('close')
      }
    },
    onClose() {
      this.resetSaveDashboardAsState()
      this.$emit('close')
    },
    onChangeName({ value }) {
      this.$data.name = value
    },
    onChangeProject({ value }) {
      this.$data.projectId = value
    },
  },
}
</script>
