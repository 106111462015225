<template>
  <v-row class="productcards--container">
    <template v-if="loading">
      <v-col
        v-for="n in 5"
        :key="n"
        class="productcard--item px-1"
      >
        <v-skeleton-loader
          type="image"
          width="100%"
          height="40"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col
        v-for="(product, index) in products"
        :key="`${product.name}-${product._id}`"
        class="productcard--item px-0"
      >
        <r-product-card
          :id="product.productId"
          :name="product.name"
          :color="getColor(index)"
          :is-selected="selectedProductIds.includes(product.productId)"
          :is-hovered="hoveredProductId === product.productId"
          @click="onClickProductCard"
          @mouseenter="onHoverIn"
          @mouseleave="onHoverOut"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import RProductCard from '@/components/library/molecules/RProductCard'
import { getProductColorForNumber } from '@/utils/constants'

export default {
  name: 'ProductCardSelector',
  components: {
    RProductCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedProductIds: {
      type: Array,
      default: () => [],
    },
    hoveredProductId: {
      type: String,
      default: null,
    },
  },
  methods: {
    onHoverIn({ value }) {
      return this.$emit('change:hovered-product', { value })
    },
    onHoverOut() {
      return this.$emit('change:hovered-product', { value: null })
    },
    onClickProductCard({ value }) {
      return this.$emit('change:highlighted-product', { value })
    },
    getColor(index) {
      return getProductColorForNumber(index)
    },
  },
}
</script>

<style scoped>
.productcards--container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.productcard--item {
  min-width: calc(100% - 32px);
}
</style>
