<template>
  <div
    class="entityfilters"
  >
    <div class="entityfilters--fields">
      <v-row>
        <v-col cols="12">
          <p>
            Separated By
          </p>
          <r-select
            :items="separatedByItems"
            :show-search="false"
            :allow-empty="false"
            @change="(e) => onSelectItemChange(e, 'separateBy')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="entityhighlighted">
            <p>
              Highlight
            </p>
            <r-select
              :disabled="disabled"
              :items="highLightItems"
              :show-search="false"
              :allow-empty="false"
              @change="(e) => onSelectItemChange(e, 'highlight')"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <p>
            Color By
          </p>
          <r-select
            :items="colorByItems"
            :show-search="false"
            :allow-empty="false"
            @change="(e) => onSelectItemChange(e, 'colorBy')"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import RSelect from '@/components/library/molecules/RSelect'

export default {
  name: 'EntitySettings',
  components: {
    RSelect,
  },
  props: {
    appliedSeparateByFilter: {
      type: String,
    },
    appliedHighlightFilter: {
      type: String,
    },
    appliedColorByFilter: {
      type: String,
    },
  },
  data: () => ({
    separatedByItems: [
      { label: 'None', value: 'none', selected: true },
      { label: 'Star Rating', value: 'rating', selected: false },
      { label: 'Sentiment Score', value: 'netSentiment', selected: false },
      { label: 'Time', value: 'time', selected: false },
      { label: 'Url', value: 'url', selected: false },
    ],
    highLightItems: [
      { label: 'None', value: 'none', selected: true },
      { label: 'Common words', value: 'commonWords', selected: false },
      { label: 'Unique words', value: 'uniqueWords', selected: false },
    ],
    colorByItems: [
      { label: 'Sentiment score', value: 'netSentiment', selected: true },
      { label: 'Star rating', value: 'rating', selected: false },
      { label: 'Time', value: 'time', selected: false },
    ],
  }),
  computed: {
    disabled() {
      return this.separatedByItems.find((it) => it.selected).value === 'none'
    },
  },
  mounted() {
    this.selectItems()
  },
  methods: {
    onSelectItemChange(item, type) {
      if (item) {
        const { value } = item
        this.$emit('apply', { [type]: value })
        this.$emit('close')
      }
    },
    selectItems() {
      this.separatedByItems = this.separatedByItems.map((item) => ({
        ...item, selected: item.value === this.appliedSeparateByFilter,
      }))
      this.highLightItems = this.highLightItems.map((item) => ({
        ...item, selected: item.value === this.appliedHighlightFilter,
      }))
      this.colorByItems = this.colorByItems.map((item) => ({
        ...item, selected: item.value === this.appliedColorByFilter,
      }))
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
.entityfilters {
  width: 326px;
  padding: 16px;
}

.entityhighlighted {
  background: var(--r-light-grey);
  padding: 8px 8px 8px 24px;
}
</style>
