import { render, staticRenderFns } from "./EntitySettings.vue?vue&type=template&id=683b59a8&scoped=true&"
import script from "./EntitySettings.vue?vue&type=script&lang=js&"
export * from "./EntitySettings.vue?vue&type=script&lang=js&"
import style0 from "./EntitySettings.vue?vue&type=style&index=0&id=683b59a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683b59a8",
  null
  
)

export default component.exports