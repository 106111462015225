<template>
  <div class="multiproductdashboard">
    <div class="pa-0">
      <div class="px-14">
        <!-- Header and Tabs -->
        <div class="header">
          <div class="py-6">
            <dashboard-header
              class="multiproductdashboard--header"
              :pinned="pinned"
              :name="name"
              :show-reviews="showReviews"
              @show:reviews="onShowReviews"
            />
          </div>

          <v-row class="multiproductdashboard--tabs ma-0 mt-2">
            <v-col class="pa-0">
              <r-grouped-tabs
                :selected-group-tab="selectedGroupTab"
                :selected-sub-tab="selectedSubTab"
                :items="groupedTabs"
                :show-delete-group="true"
                @change="onChangeGroupTab"
                @change:sub-tab="onChangeSubTab"
                @on:delete-group="onDeleteGroup"
              >
                <template #separateTab>
                  <r-anchored-popup
                    v-slot="{ onClose }"
                    anchor="top-left"
                    :disabled="spdLimitReached"
                    label="Product deep dive + "
                  >
                    <add-single-product-dashboard
                      :on-close="onClose"
                      :preselected-products="selectedProducts"
                      @on:add-dashboards="onAddDashboards"
                    />
                  </r-anchored-popup>
                </template>
              </r-grouped-tabs>
            </v-col>
          </v-row>
        </div>

        <!-- Filter and Chips -->
        <v-row
          v-if="!fetchingDashboardsData"
          class="multiproductdashboard--filter-row mx-0"
        >
          <v-col
            class="pa-0 mb-3"
            cols="6"
          >
            <dashboard-date-selector
              class="d-flex"
              :start-date="startDate"
              :end-date="endDate"
              @change="onChangeDateRange"
            />
          </v-col>
          <v-col
            class="pa-0"
            cols="6"
          >
            <dashboard-filter-chips class="d-flex flex-wrap flex-row-reverse" />
          </v-col>
        </v-row>
        <v-row
          v-else
          class="multiproductdashboard--filter-row mx-0"
        >
          <v-skeleton-loader
            class="elevation-0 filterrow--loader"
            type="list-item"
            height="30"
            width="100%"
          />
        </v-row>
      </div>

      <v-divider />
    </div>
    <single-product-dashboard-content
      v-if="showSPD"
      ref="spdcontent"
      :selected-group-tab="selectedGroupTab"
      :selected-sub-tab="selectedSubTab"
      :show-reviews="showReviews"
      @on:show-reviews="onShowReviews"
    />
    <multi-product-dashboard-content
      v-else
      ref="mpdcontent"
      :selected-group-tab="selectedGroupTab"
      :selected-sub-tab="selectedSubTab"
      :show-reviews="showReviews"
      @on:show-reviews="onShowReviews"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DashboardHeader from '@/components/app/dashboards/common/DashboardHeader'
import RGroupedTabs from '@/components/library/molecules/RGroupedTabs'
import DashboardFilterChips from '@/components/app/dashboards/DashboardFilterChips'
import DashboardDateSelector from '@/components/app/dashboards/DashboardDateSelector'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup.vue'
import AddSingleProductDashboard from '@/components/app/dashboards/AddSingleProductDashboard.vue'
import SingleProductDashboardContent from '@/components/app/dashboards/SingleProductDashboardContent.vue'
import MultiProductDashboardContent from '@/components/app/dashboards/MultiProductDashboardContent.vue'
import {
  MAX_DASHBOARD_TAB_GROUPS,
  MULTI_PRODUCT_GROUPED_TABS,
  SCROLL_RESET_POSITION,
  SINGLE_PRODUCT_GROUPED_TABS,
  TYPES_OF_DASHBOARD,
  getProductColorForNumber,
} from '@/utils/constants'

const SINGLE_PRODUCTS_GROUPED_TAB_MAPPER = (key, label, index) => [
  {
    group: label,
    productId: key,
    color: getProductColorForNumber(index),
    items: [
      {
        label: 'Summary',
        key: `single-product--summary--${key}`,
      },
      {
        label: 'Trending',
        key: `single-product--trending--${key}`,
      },
      {
        label: 'Entity',
        key: `single-product--entity--${key}`,
      },
      {
        label: 'Location',
        key: `single-product--location--${key}`,
      },
    ],
  },
]

export default {
  name: 'MultiProductDashboard',
  components: {
    MultiProductDashboardContent,
    SingleProductDashboardContent,
    AddSingleProductDashboard,
    RAnchoredPopup,
    DashboardDateSelector,
    DashboardHeader,
    RGroupedTabs,
    DashboardFilterChips,
  },
  data: () => ({
    isSPDTabSelected: false,
    showReviews: false,
    selectedGroupTab: 0,
    selectedSubTab: 0,
    showDateRangePicker: false,
    startDate: '',
    endDate: '',
    MULTI_PRODUCT_GROUPED_TABS,
    SINGLE_PRODUCT_GROUPED_TABS,
    groupedTabs: [],
    selectedProducts: [],
    productId: undefined,
    MAX_DASHBOARD_TAB_GROUPS,
  }),
  computed: {
    ...mapGetters('dashboardsData', [
      'name',
      'numReviews',
      'pinned',
      'dashboardDataStartDate',
      'dashboardDataEndDate',
      'dashboardProductIds',
      'dashboardId',
      'productsForSPD',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
      'dateRange',
      'dateRangeAllIsSelected',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
    ]),
    showSPD() {
      return this.isSPDTabSelected && this.productId
    },
    spdLimitReached() {
      return this.productsForSPD.length >= MAX_DASHBOARD_TAB_GROUPS
    },
  },
  watch: {
    dateRange() {
      const { minDate, maxDate } = this.appliedFilters
      if (minDate && maxDate) {
        this.startDate = minDate
        this.endDate = maxDate
      }
    },
    dashboardDataStartDate() {
      if (this.dateRangeAllIsSelected) {
        this.startDate = this.dashboardDataStartDate
        this.endDate = this.dashboardDataEndDate
      }
    },
  },
  async mounted() {
    if (this.productsForSPD.length) {
      this.selectedProducts = this.productsForSPD
    }

    this.groupedTabs = this.generateTabs()
    await this.fetchData()

    if (this.dateRangeAllIsSelected) {
      this.startDate = this.dashboardDataStartDate
      this.endDate = this.dashboardDataEndDate
    }
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    ...mapActions('dashboardsData', [
      'fetchDashboardsData',
      'fetchDashboardsReviews',
      'setProductsAsSpd',
    ]),
    ...mapActions('entityMaps', [
      'resetState',
    ]),
    onShowReviews({ value }) {
      this.showReviews = value
    },
    async scrollUpDashboardContent() {
      if (this.showSPD) {
        return this.$refs.spdcontent.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
      }

      return this.$refs.mpdcontent.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
    },
    async selectMainGroup() {
      this.productId = null

      this.selectedGroupTab = 0
      this.selectedSubTab = 0

      this.isSPDTabSelected = false

      await this.$nextTick()
      await this.setDashboardFilters({ subGroup: null })
      await this.scrollUpDashboardContent()
    },
    async selectSubGroup(index) {
      const { productId } = this.groupedTabs[index]
      this.productId = productId

      this.selectedGroupTab = index
      this.selectedSubTab = 0

      this.isSPDTabSelected = true

      await this.$nextTick()
      await this.setDashboardFilters({
        subGroup: { type: TYPES_OF_DASHBOARD.SINGLE_PRODUCT, sourceId: productId },
      })
      await this.scrollUpDashboardContent()
    },
    async onChangeGroupTab(index) {
      if (this.selectedGroupTab === index) {
        return
      }

      if (index === 0) {
        await this.selectMainGroup()
      } else {
        await this.selectSubGroup(index)
      }
    },
    async onChangeSubTab(index) {
      this.selectedSubTab = index
      this.scrollUpDashboardContent()
    },
    getSortingPayload() {
      return {
        sort: this.sort,
        sortDir: this.sortDir,
      }
    },
    async fetchData() {
      const params = {
        dashboardId: this.dashboardId,
      }

      await this.fetchDashboardsData(params)
      this.fetchDashboardsReviews(params)
    },
    onChangeDateRange({ dateRange }) {
      this.setDashboardFilters({ dateRange })

      if (!dateRange) {
        return
      }

      const [startDate, endDate] = dateRange
      this.startDate = startDate
      this.endDate = endDate
    },
    onAddDashboards({ value }) {
      this.selectedProducts = value.map(({ label: name, value: _id }) => ({ name, _id }))
      this.groupedTabs = this.generateTabs()

      const activeDashboardIsRemoved = !value.map(({ value: _id }) => _id).includes(this.productId)
      if (activeDashboardIsRemoved) {
        this.selectMainGroup()
      }

      this.setProductsAsSpd(this.selectedProducts)
    },
    async onDeleteGroup({ value }) {
      const { productId } = this.groupedTabs[value]
      this.selectedProducts = this.selectedProducts.filter(({ _id }) => productId !== _id)
      this.groupedTabs = this.generateTabs()

      // if active tab deleted then moving active to first tab
      if (this.productId === productId) {
        await this.selectMainGroup()
      }

      this.setProductsAsSpd(this.selectedProducts)
    },
    generateTabs() {
      const mainTabs = MULTI_PRODUCT_GROUPED_TABS
      const subGroupTabs = this.selectedProducts
        .map(({ name, _id: key }, index) => SINGLE_PRODUCTS_GROUPED_TAB_MAPPER(key, name, index))
        .flat()

      return [
        ...mainTabs,
        ...subGroupTabs,
      ]
    },
  },
}
</script>

<style scoped>
.multiproductdashboard--tabs {
  margin-top: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--r-border-color);
}
.multiproductdashboard--filter-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

/deep/ .v-skeleton-loader__image {
  height: 1000px;
}
/deep/ .multiproductdashboard--tabs .v-skeleton-loader__button  {
  height: 28px;
}
/deep/ .multiproductdashboard--filter-row .v-skeleton-loader__button,
/deep/ .multiproductdashboard--filter-row .v-skeleton-loader__chip {
  height: 24px;
}

.multiproductdashboard--date-range > div {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}
.multiproductdashboard--date-range i {
  font-weight: 700;
  margin-left: 10px;
}

/deep/ .content-wrapper {
  margin-top: -46px
}

/deep/ .v-tab {
  min-height: 33px;
}

</style>
