<template>
  <div class="productentitiescomparison">
    <v-row v-if="fetchingDashboardsData || fetchingProductsEntities">
      <v-col
        v-for="n in 10"
        :key="n"
        cols="12"
        class="productentitiescomparison-loader pa-0 mb-5"
      >
        <v-skeleton-loader
          type="image"
          width="100%"
          height="25"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="!productsEntities.length">
      <v-col
        class="productentitiescomparison--no-data"
        cols="12"
      >
        <div class="d-flex align-center justify-space-between">
          <r-no-results-message />
          <r-anchored-popup
            v-slot="{ onClose }"
            ref="productentitiescomparison-product-selector"
            label="Select Products"
          >
            <product-selection-popup
              :preselected-sort="sort"
              :preselected-sort-dir="sortDir"
              :preselected-product-ids="selectedProducts"
              @change:selected-products="onChangeSelectedProducts"
              @onClose="onClose"
            />
          </r-anchored-popup>
        </div>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      v-for="(entityData, index) in productsEntities"
      v-else
      :key="`${entityData.entity}-${index}`"
      class="pa-0"
      cols="12"
    >
      <v-col cols="12">
        <r-component-header class="productentitiescomparison--header-border">
          <r-tabs
            class="productentitiescomparison--tabs d-flex"
            :items="tabItems"
            :selected-tab="selectedTabByEntity[entityData.entity]"
            style="width:100%"
            @change="(i) => onChangeEntitySelectedTab({
              entity: entityData.entity, selectedTab: i
            })"
          >
            <template #start>
              <p class="productentitiescomparison--tab-title--wrapper">
                <span
                  class="productentitiescomparison--tab-title text-truncate px-1"
                  :title="entityData.entity"
                >
                  {{ entityData.entity }}
                </span>
                <span class="productentitiescomparison--tab-title--total">
                  ({{ productsEntitiesBreakdownTotal(entityData) }})
                </span>
              </p>
            </template>
            <template #end>
              <v-row
                class="productentitiescomparison-product-selector ma-0"
              >
                <r-anchored-popup
                  v-slot="{ onClose }"
                  ref="productentitiescomparison-product-selector"
                  label="Select Products"
                >
                  <product-selection-popup
                    :preselected-sort="sort"
                    :preselected-sort-dir="sortDir"
                    :preselected-product-ids="selectedProducts"
                    @change:selected-products="onChangeSelectedProducts"
                    @onClose="onClose"
                  />
                </r-anchored-popup>
              </v-row>
            </template>
          </r-tabs>
        </r-component-header>
        <r-tab-items
          :selected-tab="selectedTabByEntity[entityData.entity]"
          class="mr-4 mt-4"
          style="width:100%; min-height: 730px"
        >
          <r-tab-item label="Rating">
            <r-error-message
              class="my-2"
              :errors="[fetchProductsEntitiesError]"
            />
            <v-row v-if="noData">
              <v-col>
                <p>
                  {{ $t('dashboards.multiProduct.message.noData', { type: 'rating' }) }}
                </p>
              </v-col>
            </v-row>
            <v-row
              v-for="productData in productsEntitiesRatingBreakdown({
                data: entityData.products
              })"

              v-else
              :key="productData.productId"
              class="mx-0 mt-4 mb-1"
            >
              <r-stacked-bar-chart
                :properties="RATING_KEYS"
                :data="productData"
              />
            </v-row>
            <v-row
              class="ma-0 mb-1"
              justify="space-between"
            >
              <v-col>
                <p
                  v-for="i in [5, 4, 3, 2, 1]"
                  :key="`${i}`"
                  class="d-inline mx-2"
                >
                  <v-icon
                    :style="legendStyleFromIndex(i)"
                    small
                  >
                    fiber_manual_record
                  </v-icon>
                  {{ $t(`global.keys.${i}`) }}
                </p>
              </v-col>
              <v-col>
                <r-pagination
                  :page="page"
                  :total-pages="Math.ceil(totalProductsEntities / pageSize)"
                  justify="end"
                  @click:page="onClickPage"
                />
              </v-col>
            </v-row>
          </r-tab-item>
          <r-tab-item label="Sentiment">
            <r-error-message
              class="my-2"
              :errors="[fetchProductsEntitiesError]"
            />
            <template>
              <v-row v-if="noData">
                <v-col>
                  <p>
                    {{ $t('dashboards.multiProduct.message.noData', { type: 'sentiment' }) }}
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-for="productData in productsEntitiesSentimentBreakdown({
                  data: entityData.products
                })"

                v-else
                :key="productData.productId"
                class="mx-0 mt-4 mb-1"
              >
                <r-stacked-bar-chart
                  :properties="SENTIMENT_KEYS"
                  :data="productData"
                />
              </v-row>

              <v-row

                class="ma-0 mb-1"
                justify="space-between"
              >
                <v-col>
                  <p
                    v-for="sentiment in SENTIMENT_KEYS"
                    :key="`${sentiment}`"
                    class="d-inline mx-2"
                  >
                    <v-icon
                      :style="legendStyle(sentiment)"
                      small
                    >
                      fiber_manual_record
                    </v-icon>
                    {{ $t(`global.keys.${sentiment}`) }}
                  </p>
                </v-col>

                <v-col>
                  <r-pagination
                    :page="page"
                    :total-pages="Math.ceil(totalProductsEntities / pageSize)"
                    justify="end"
                    @click:page="onClickPage"
                  />
                </v-col>
              </v-row>
            </template>
          </r-tab-item>
        </r-tab-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RTabs from '@/components/library/molecules/RTabs'
import RTabItem from '@/components/library/molecules/RTabItem'
import RTabItems from '@/components/library/molecules/RTabItems'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import ProductSelectionPopup from '@/components/app/dashboards/common/ProductSelectionPopup'
import RStackedBarChart from '@/components/library/atoms/RStackedBarChart'
import RComponentHeader from '@/components/library/atoms/RComponentHeader.vue'
import RPagination from '@/components/library/molecules/RPagination'

import { mapActions, mapGetters, mapState } from 'vuex'
import chartKeyToColor from '@/utils/chartKeyToColor'
import RNoResultsMessage from '@/components/library/atoms/RNoResultsMessage'

const RATING_KEYS = ['five', 'four', 'three', 'two', 'one']
const SENTIMENT_KEYS = ['positive', 'neutral', 'negative']
const COMPONENT_ID = 'product-entities-comparison'

export default {
  name: 'ProductEntitiesComparison',
  components: {
    RNoResultsMessage,
    RTabs,
    RTabItem,
    RTabItems,
    RErrorMessage,
    RAnchoredPopup,
    RComponentHeader,
    RStackedBarChart,
    ProductSelectionPopup,
    RPagination,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      sort: 'name',
      sortDir: 'asc',
      selectedProducts: [],
      RATING_KEYS,
      SENTIMENT_KEYS,
      tabItems: [
        { label: 'Rating', icon: 'mdi-star' },
        { label: 'Sentiment', icon: 'mdi-poll' },
      ],
      selectedTabByEntity: {},
    }
  },
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    ...mapGetters('dashboardsData', [
      'getComponentSettings',
      'dashboardProductIds',
    ]),
    ...mapState('dashboardsData', [
      'allowComponentsToFetch',
      'productsEntities',
      'fetchProductsEntitiesError',
      'fetchingDashboardsData',
      'fetchingProductsEntities',
      'totalProductsEntities',
    ]),
    noData() {
      return this.productsEntities.length === 0
    },
  },
  watch: {
    appliedFilters() {
      this.fetchData()
    },
  },
  mounted() {
    if (this.allowComponentsToFetch) { this.fetchData() }
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchDashboardProductsEntities',
      'saveDashboardComponentSettings',
    ]),
    async onChangeSelectedProducts({
      value, search, sort, sortDir,
    }) {
      const productIds = value ? value.map(({ _id }) => _id) : null
      await this.saveDashboardComponentSettings({
        componentId: COMPONENT_ID,
        productIds,
        sort,
        sortDir,
        search,
      })
      this.page = 1
      this.sort = sort
      this.sortDir = sortDir
      this.selectedProducts = productIds
      this.fetchData()
    },
    onChangeEntitySelectedTab({ entity, selectedTab }) {
      this.selectedTabByEntity = {
        ...this.selectedTabByEntity,
        [entity]: selectedTab,
      }
    },
    fetchData() {
      const { page, pageSize } = this.$data
      const { sort = 'name', sortDir = 'asc', productIds = [] } = this.getComponentSettings(COMPONENT_ID)
      this.sort = sort
      this.sortDir = sortDir
      this.selectedProducts = productIds?.length ? productIds : this.dashboardProductIds
      this.fetchDashboardProductsEntities({ page, pageSize })
        .then(() => {
          this.selectedTabByEntity = this.productsEntities.reduce((acc, { entity }) => (
            { ...acc, [entity]: 0 }
          ), {})
        })
    },
    productsEntitiesRatingBreakdown({ data }) {
      return data.map((datum) => ({
        name: datum.name,
        one: datum['1'],
        two: datum['2'],
        three: datum['3'],
        four: datum['4'],
        five: datum['5'],
        total: datum['1'] + datum['2'] + datum['3'] + datum['4'] + datum['5'],
      }))
    },
    productsEntitiesSentimentBreakdown({ data }) {
      return data.map(({
        productId,
        name,
        negative,
        neutral,
        positive,
      }) => ({
        name,
        productId,
        negative,
        neutral,
        positive,
        total: negative + neutral + positive,
      }))
    },
    productsEntitiesBreakdownTotal({ entity, products }) {
      const selectedTabValue = this.selectedTabByEntity[entity]

      switch (selectedTabValue) {
        case 0:
          return products.reduce((acc, {
            1: oneStar,
            2: twoStar,
            3: threeStar,
            4: fourStar,
            5: fiveStar,
          }) => acc + oneStar + twoStar + threeStar + fourStar + fiveStar, 0)
        case 1:
          return products.reduce((acc, {
            negative,
            neutral,
            positive,
          }) => acc + negative + neutral + positive, 0)
        default:
          return 0
      }
    },
    legendStyle(breakdownKey) {
      return {
        color: chartKeyToColor(breakdownKey),
        'font-size': '11.5px',
        'font-weight': 400,
        'line-height': '20px',
        padding: '0 5px',
      }
    },
    legendStyleFromIndex(index) {
      const indexToKey = {
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four',
        5: 'five',
      }
      return this.legendStyle(indexToKey[index])
    },
    onClickPage({ value: page }) {
      this.page = page
      this.fetchData()
    },
  },
}
</script>

<style scoped>
.productentitiescomparison /deep/ .rtabs--start-slot {
  display: flex;
}

.productentitiescomparison--tab-title--wrapper {
  margin: 0;
  display: flex;
  align-self: center;
}

.productentitiescomparison--tab-title.text-truncate {
  max-width: 20ch;
}

.productentitiescomparison--tab-title.text-truncate:hover {
  cursor: default;
}

.productentitiescomparison--tab-title,
.productentitiescomparison--tab-title--total {
  font-style: normal;
  font-weight: 700;
  font-size: 1.07692307692rem;
  line-height: 20px;
  letter-spacing: -0.02px;
  color: var(--r-dark-grey);
  justify-content: space-between;
  align-items: center;
}

.productentitiescomparison--tab-title--total {
  margin-right: 30px;
  font-weight: 300;
}

/deep/ .rtabitems.content.v-item-group.v-tabs-items {
  min-height: auto !important;
  overflow: visible;
}

.productentitiescomparison-product-selector {
  position: relative;
  top: -8px
}

.productentitiescomparison--no-data {
  height: 75vh;
}
</style>
