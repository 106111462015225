<template>
  <div class="entityprogress">
    <v-row
      v-if="isData"
      no-gutters
      align-content="space-between"
    >
      <v-col>
        <div class="d-flex justify-start">
          <r-select
            class="entityprogress--entity-filters p-0 mr-2"
            :items="entityFilterItems"
            :show-search="false"
            :allow-empty="false"
            @change="onChangeEntityFilter"
          />
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-end">
          <r-select
            class="entityprogress--breakdown-filters p-0 ml-2"
            :items="breakdownFilterItems"
            :show-search="false"
            :allow-empty="false"
            @change="onChangeBreakdownFilter"
          />
        </div>
      </v-col>
    </v-row>
    <r-sentiment-bar-chart
      v-if="selectedEntityValue === 'all'"
      :loading="loading"
      :width="width"
      :breakdown="selectedBreakdownValue"
      legend-enabled
      tooltip-enabled
      :data="barChartData"
      @click:entity="onClickEntity"
    />
    <entity-progress-time-line-chart
      v-else
      class="entityprogress--chart mx-4 my-8"
      :data="timeLineChartData"
      :width="width"
      :loading="fetchingEntityProgressHistory"
    />
  </div>
</template>

<script>
import RSentimentBarChart from '@/components/library/molecules/RSentimentBarChart'
import RSelect from '@/components/library/molecules/RSelect'
import EntityProgressTimeLineChart from '@/components/app/dashboards/EntityProgressTimeLineChart.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { COMPARISON_CHART_COLORS } from '@/utils/constants'
import dateStrToDate from '@/utils/dateStrToDate'

const ENTITY_FILTER_ALL_OPTION = {
  name: 'All',
  value: 'all',
}

const BREAKDOWN_FILTER_OPTIONS = {
  SENTIMENT: {
    name: 'Sentiment',
    value: 'sentiment',
  },
  RATING: {
    name: 'Rating',
    value: 'rating',
  },
}

export default {
  name: 'EntityProgress',
  components: {
    RSentimentBarChart,
    RSelect,
    EntityProgressTimeLineChart,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 900,
    },
  },
  data: () => ({
    selectedEntityValue: 'all',
    selectedBreakdownValue: 'sentiment',
    fetchingEntityProgressHistory: false,
  }),
  computed: {
    ...mapGetters('dashboardsData', [
      'dashboardId',
      'sentimentBarChartData',
      'ratingsBarChartData',
    ]),
    ...mapGetters('entityProgress', [
      'reviewsSentimentHistory',
      'reviewsRatingHistory',
    ]),
    ...mapState('dashboardsData', [
      'entities',
    ]),
    ...mapState('entityProgress', [
      'fetchingEntityProgressSentimentHistory',
      'fetchingEntityProgressRatingHistory',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    isData() {
      const { barChartData, timeLineChartData } = this
      return !!barChartData.length || !!timeLineChartData.length
    },
    barChartData() {
      if (this.selectedBreakdownValue === 'sentiment') {
        return this.sentimentBarChartData
      }
      return this.ratingsBarChartData
    },
    timeLineChartData() {
      return this.getTimeLineSeries()
    },
    entityFilterItems() {
      const limitedEntities = this.entities.slice(0, 10)
      const { entityGroupId, entity } = this.selectedEntityValue

      return [{
        label: ENTITY_FILTER_ALL_OPTION.name,
        value: ENTITY_FILTER_ALL_OPTION.value,
        selected: this.selectedEntityValue === ENTITY_FILTER_ALL_OPTION.value,
      }].concat(
        limitedEntities.map((item) => ({
          key: item.entity,
          label: item.entity,
          value: item,
          selected: (entityGroupId && entityGroupId === item?.entityGroupId)
            || (entity && entity === item.entity),
        })),
      )
    },
    breakdownFilterItems() {
      return Object.values(BREAKDOWN_FILTER_OPTIONS)
        .map((option) => ({
          label: option.name,
          value: option.value,
          selected: option.value === this.selectedBreakdownValue,
        }))
    },
  },
  watch: {
    fetchingEntityProgressHistory() {
      const { fetchingEntityProgressSentimentHistory, fetchingEntityProgressRatingHistory } = this
      return fetchingEntityProgressSentimentHistory || fetchingEntityProgressRatingHistory
    },
    appliedFilters(newFilter, oldFilter) {
      const {
        entity: newEntity,
        entityGroupId: newEntityGroupId,
        entityMapId: newEntityMapId,
      } = newFilter
      const {
        entity: oldEntity,
        entityGroupId: oldEntityGroupId,
        entityMapId: oldEntityMapId,
      } = oldFilter

      /**
       * If the `entityMapId` filter has been removed AND before removing it
       * the `selectedEntityValue` was `entityGroupId`,
       * then reset the `entityMapId` and `entityGroupId` filters,
       * set the `selectedEntityValue` to the default/'all'
       */
      if (!newEntityMapId && this.selectedEntityValue?.entityGroupId) {
        this.setDashboardFilters({ entityMapId: null })
        this.setDashboardFilters({ entityGroupId: null })
        this.selectedEntityValue = ENTITY_FILTER_ALL_OPTION.value
        /**
         * If the new `entity` filter from the dashboardFilters has been applied
         * AND it doesn't match the old one, then set `selectedEntityValue`
         * to the new selected entity value
         */
      } else if (newEntity && newEntity !== oldEntity) {
        this.selectedEntityValue = { entity: newEntity }
        /**
         * If the new `entityGroupId` filter from the dashboardFilters
         * has been applied AND it does not match the old one,
         * then set `selectedEntityValue` to the new selected entityGroupId value
         */
      } else if (newEntityGroupId && newEntityGroupId !== oldEntityGroupId) {
        this.selectedEntityValue = { entityGroupId: newEntityGroupId }
        /**
         * If there are no new applied dashboard filters that include
         * entity, entityMapId or entityGroupId
         * AND the new and old respective filter values are not null nor undefined,
         * then set `selectedEntityValue` to the default/'all'
         */
      } else if ((!newEntity && newEntity !== oldEntity)
              || (!newEntityMapId && newEntityMapId !== oldEntityMapId)
              || (!newEntityGroupId && newEntityGroupId !== oldEntityGroupId)) {
        this.selectedEntityValue = ENTITY_FILTER_ALL_OPTION.value
      }
      this.fetchHistoryData()
    },
    entityFilterItems(filters) {
      const defaultOption = ENTITY_FILTER_ALL_OPTION.value
      const hasSelectedValue = filters.some(({ selected }) => selected)

      if (!hasSelectedValue && this.selectedEntityValue !== defaultOption) {
        this.selectedEntityValue = defaultOption
      }
    },
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapActions('entityProgress', [
      'fetchEntityProgressSentimentHistory',
      'fetchEntityProgressRatingHistory',
      'resetState',
    ]),
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    onChangeEntityFilter(e) {
      const { value } = e
      this.selectedEntityValue = value
      this.fetchHistoryData()
    },
    onChangeBreakdownFilter(e) {
      const { value } = e
      this.selectedBreakdownValue = value
      this.fetchHistoryData()
    },
    onClickEntity({ value }) {
      this.$emit('click:entity', { value })
    },
    fetchHistoryData() {
      if (this.selectedEntityValue === 'all') {
        return
      }

      const { entity, entityGroupId } = this.selectedEntityValue
      const queryParams = entityGroupId ? { entityGroupId } : { entity }
      if (this.selectedBreakdownValue === 'sentiment') {
        this.fetchEntityProgressSentimentHistory({
          dashboardId: this.dashboardId,
          ...queryParams,
        })
      }
      if (this.selectedBreakdownValue === 'rating') {
        this.fetchEntityProgressRatingHistory({
          dashboardId: this.dashboardId,
          ...queryParams,
        })
      }
    },
    getBreakDownSeries(breakdownData) {
      const isSentiment = this.selectedBreakdownValue === 'sentiment'
      const colors = [...COMPARISON_CHART_COLORS[isSentiment ? 'SENTIMENT' : 'RATING_DISTRIBUTION']]
      const baseSeries = []

      if (!breakdownData.length) {
        return baseSeries
      }
      const breakdownKeys = Object.keys(breakdownData[0].volume)

      const { length } = breakdownKeys

      for (let i = 0; i < length; i += 1) {
        baseSeries.push({
          name: this.$t(`global.keys.${breakdownKeys[i].toLowerCase()}`),
          color: colors[i],
          data: [],
          yAxis: 0,
          legendIndex: isSentiment ? i : length - i,
        })
      }

      breakdownData.forEach(({ volume, dateFrom: date }) => breakdownKeys.forEach(
        (key, index) => baseSeries[index].data.push(
          {
            x: dateStrToDate(date),
            y: volume[key],
            labelKey: key,
          },
        ),
      ))

      return baseSeries
    },
    getTimeLineSeries() {
      if (this.selectedBreakdownValue === 'sentiment') {
        return this.getBreakDownSeries(this.reviewsSentimentHistory)
      }
      return this.getBreakDownSeries(this.reviewsRatingHistory).reverse()
    },
  },
}
</script>

<style scoped>
.entityprogress--entity-filters,
.entityprogress--breakdown-filters {
  width: 160px;
}
</style>
