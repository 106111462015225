<template>
  <div>
    <div
      v-if="singleProductDashboardErrorMessage"
      class="mt-6 pa-4"
    >
      <r-error-message :errors="[singleProductDashboardErrorMessage]" />
    </div>

    <products-dropdown
      select-label="Add products"
      :preselected-products="preselectedProducts"
      @change="onSelectProducts"
    />
    <div class="d-flex justify-end py-4 pr-4">
      <r-button
        outlined
        class="mr-2"
        label="Cancel"
        @click="onClose"
      />
      <r-button
        :disabled="disableSaveButton"
        label="Save"
        @click="onAddDashboards"
      />
    </div>
  </div>
</template>
<script>
import ProductsDropdown from '@/components/app/data/ProductsDropdown.vue'
import RButton from '@/components/library/atoms/RButton.vue'
import RErrorMessage from '@/components/library/atoms/RErrorMessage.vue'
import { MAX_DASHBOARD_TAB_GROUPS } from '@/utils/constants'

const MAX_DASHBOARD_TAB_GROUP_ERROR_MESSAGE = 'You can Select up to 4 products'

export default {
  name: 'AddSingleProductDashboard',
  components: {
    ProductsDropdown,
    RButton,
    RErrorMessage,
  },
  props: {
    onClose: {},
    preselectedProducts: {
      type: Array,
    },
  },
  data: () => ({
    singleProductDashboardErrorMessage: '',
    MAX_DASHBOARD_TAB_GROUPS,
    selectedProducts: [],
  }),
  computed: {
    disableSaveButton() {
      return !!this.singleProductDashboardErrorMessage || !this.selectedProducts.length
    },
  },
  methods: {
    onSelectProducts({ value }) {
      if (value.length > MAX_DASHBOARD_TAB_GROUPS) {
        this.singleProductDashboardErrorMessage = MAX_DASHBOARD_TAB_GROUP_ERROR_MESSAGE
        return
      }
      this.singleProductDashboardErrorMessage = ''
      this.selectedProducts = value
    },
    onAddDashboards() {
      this.$emit('on:add-dashboards', { value: this.selectedProducts })
      this.onClose()
    },
  },
}
</script>
<style scoped>
/deep/.productsdropdown {
  width: 600px;
  padding: 40px 15px 15px;
}

</style>
