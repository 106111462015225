var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboardfilters",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onClickApply.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dashboardfilters--fields"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('dashboardFilters.sentiment')) + " ")]), _c('r-select', {
    key: `sentiment-selector-${_vm.fetchingDashboardsData}`,
    staticClass: "sentiment-selector",
    attrs: {
      "show-search": false,
      "items": _vm.sentimentItems
    },
    on: {
      "change": _vm.onChangeSentiment
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('dashboardFilters.urls')) + " ")]), _c('r-select', {
    key: `urls-selector-${_vm.fetchingDashboardsData}`,
    staticClass: "urls-selector",
    attrs: {
      "multiple": "",
      "items": _vm.urlItems
    },
    on: {
      "change": _vm.onChangeUrls
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v(_vm._s(this.$t('dashboardFilters.starRating')) + " ")]), _c('r-rating-filter-selector', {
    attrs: {
      "pre-selected-items": _vm.selectedRatingItems
    },
    on: {
      "change": _vm.onChangeRatingFilterSelector
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('dashboardFilters.hostnames')) + " ")]), _c('r-select', {
    key: `hostnames-selector-${_vm.fetchingDashboardsData}`,
    staticClass: "hostnames-selector",
    attrs: {
      "multiple": "",
      "items": _vm.hostnameItems
    },
    on: {
      "change": _vm.onChangeHostnames
    }
  }), _c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboardFilters.entityMaps')) + " ")]), _c('r-select', {
    key: `entitymaps-selector-${_vm.fetchingDashboardsData}`,
    staticClass: "entitymaps-selector",
    attrs: {
      "items": _vm.entityMapsSelectorItems
    },
    on: {
      "change": _vm.onChangeEntityMap
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-col', {
          staticClass: "d-flex pa-0 rselectitem"
        }, [_c('button', {
          staticClass: "entitymaps-selector--create-new-entity-map-button rselectitem--row",
          on: {
            "click": _vm.onClickCreateNewEntityMap
          }
        }, [_c('v-icon', {
          staticClass: "pl-2",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" add ")]), _c('span', [_vm._v(_vm._s(_vm.$t('dashboardFilters.createEntityMap')))])], 1)])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "dashboardfilters--actionbtns mt-2 d-flex justify-end align-center"
  }, [_c('r-button', {
    staticClass: "mr-1",
    attrs: {
      "label": _vm.$t('global.actions.cancel'),
      "outlined": ""
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.$t('global.actions.apply'),
      "type": "submit",
      "loading": _vm.fetchingDashboardsData
    },
    on: {
      "click": _vm.onClickApply
    }
  })], 1), _vm.showCreateEntityModal ? _c('create-entity-map-modal', {
    attrs: {
      "source": "dashboard page"
    },
    on: {
      "close": _vm.onCloseCreateEntityMapModal,
      "created-new-entity-map": _vm.onNewEntityMapCreated
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }