<template>
  <v-row
    class="
      trendingdata--trending
      trendingdata--trending--box
      ma-0
      py-4
      px-5
    "
  >
    <!-- Filters -->
    <v-col
      class="trendingdata--trendingfilters"
      cols="12"
    >
      <div
        class="d-flex align-center justify-center my-2"
      >
        <div class="d-inline position-relative rdaterangepicker--last-period">
          <r-chip
            class="mr-2 rdaterangepicker--last-period-chip"
            :label="`${lastPeriodStartDate} - ${lastPeriodEndDate}`"
            :textonly="true"
            icon="expand_more"
            @click="toggleLastPeriodDateRangePicker"
          />
          <r-date-range-picker
            v-if="showLastPeriodDateRangePicker"
            :range="[lastPeriodStartDate, lastPeriodEndDate]"
            @cancel="showLastPeriodDateRangePicker = false"
            @change="onChangeLastPeriodDateRange"
          />
        </div>
        <v-divider
          class="mx-5"
          vertical
        />
        <div class="d-inline position-relative rdaterangepicker--this-period">
          <r-chip
            class="mr-2 rdaterangepicker--this-period-chip"
            :label="`${thisPeriodStartDate} - ${thisPeriodEndDate}`"
            :textonly="true"
            icon="expand_more"
            @click="toggleThisPeriodDateRangePicker"
          />
          <r-date-range-picker
            v-if="showThisPeriodDateRangePicker"
            :range="[thisPeriodStartDate, thisPeriodEndDate]"
            @cancel="showThisPeriodDateRangePicker = false"
            @change="onChangeThisPeriodDateRange"
          />
        </div>
      </div>
      <div
        class="d-flex align-center justify-center my-1"
      >
        <r-chip
          v-for="quickSelector in quickDateRangeSelectors"
          :key="quickSelector.label"
          :active="quickSelector.selected"
          :label="quickSelector.label"
          class="mr-2 quick--selector"
          @click="() => onClickQuickDateSelector(quickSelector.value)"
        />
      </div>
    </v-col>

    <!-- Data -->
    <v-col
      cols="12"
    >
      <v-row cols="ma-0">
        <!-- Last Period -->
        <v-col cols="6">
          <trending-column
            :num-reviews="lastPeriodData.numReviews || 0"
            :net-rating="lastPeriodData.netRating || 0"
            :net-sentiment="lastPeriodData.sentiment.netSentiment"
            :sentiment-breakdown="lastPeriodData.sentiment.sentimentBreakdown"
            :entities="lastPeriodData.entities"
            :loading="loading"
            @click:rating="(payload) => this.$emit('click:rating', payload)"
            @click:sentiment="(eventPayload) => this.$emit('click:sentiment', eventPayload)"
            @click:entity-name="(payload) => this.$emit('click:entity-name', payload)"
            @click:word="(payload) => this.$emit('click:word', payload)"
          />
        </v-col>

        <v-divider vertical />

        <!-- This Period -->
        <v-col cols="6">
          <trending-column
            :num-reviews="thisPeriodData.numReviews || 0"
            :net-rating="thisPeriodData.netRating || 0"
            :net-sentiment="thisPeriodData.sentiment.netSentiment"
            :sentiment-breakdown="thisPeriodData.sentiment.sentimentBreakdown"
            :entities="thisPeriodData.entities"
            :loading="loading"
            @click:sentiment="(eventPayload) => this.$emit('click:sentiment', eventPayload)"
            @click:entity-name="(payload) => this.$emit('click:entity-name', payload)"
            @click:word="(payload) => this.$emit('click:word', payload)"
            @click:rating="(payload) => this.$emit('click:rating', payload)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import RDateRangePicker from '@/components/library/molecules/RDateRangePicker'
import TrendingColumn from '@/components/app/dashboards/TrendingColumn'
import RChip from '@/components/library/atoms/RChip'
import { mapGetters } from 'vuex'

const DATE_FORMAT = 'YYYY-MM-DD'
const QUICK_SELECTORS = [
  {
    labelKey: 'dashboards.comparisonTrendingData.quickSelectors.week',
    amount: 1,
    unit: 'week',
  },
  {
    labelKey: 'dashboards.comparisonTrendingData.quickSelectors.month',
    amount: 1,
    unit: 'month',
  },
  {
    labelKey: 'dashboards.comparisonTrendingData.quickSelectors.quarter',
    amount: 1,
    unit: 'quarter',
  },
  {
    labelKey: 'dashboards.comparisonTrendingData.quickSelectors.sixMonth',
    amount: 1,
    unit: 'semester',
  },
  {
    labelKey: 'dashboards.comparisonTrendingData.quickSelectors.year',
    amount: 1,
    unit: 'year',
  },
]

export default {
  name: 'TrendingData',
  components: {
    RChip,
    TrendingColumn,
    RDateRangePicker,
  },
  props: {
    trendingData: {
      type: Object,
      require: false,
    },
    loading: {
      type: Boolean,
      require: false,
    },
  },
  data: () => ({
    showLastPeriodDateRangePicker: false,
    showThisPeriodDateRangePicker: false,
    lastPeriodStartDate: '',
    lastPeriodEndDate: '',
    thisPeriodStartDate: '',
    thisPeriodEndDate: '',
  }),
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedTrendingDataFilters',
    ]),
    lastPeriodData() {
      const { lastPeriod } = this.trendingData
      return lastPeriod
    },
    thisPeriodData() {
      const { thisPeriod } = this.trendingData
      return thisPeriod
    },
    defaultLastPeriodDateRange() {
      const { value } = this.quartersQuickSelector
      const { start, end } = value.lastPeriod
      return { minDate: start.format(DATE_FORMAT), maxDate: end.format(DATE_FORMAT) }
    },
    defaultThisPeriodDateRange() {
      const { value } = this.quartersQuickSelector
      const { start, end } = value.thisPeriod
      return { minDate: start.format(DATE_FORMAT), maxDate: end.format(DATE_FORMAT) }
    },
    quartersQuickSelector() {
      return {
        label: 'Last quarter vs this quarter',
        value: this.getRange({ unit: 'quarter' }),
        active: true,
      }
    },
    quickDateRangeSelectors() {
      return QUICK_SELECTORS.map(({ labelKey, amount, unit }) => ({
        label: this.$t(labelKey),
        value: this.getRange({ amount, unit }),
        selected: this.isQuickSelectorSelected({ amount, unit }),
      }))
    },
  },
  mounted() {
    this.loadDefaultDates()
  },
  methods: {
    isQuickSelectorSelected({ amount, unit }) {
      const range = this.getRange({ amount, unit })
      const { lastPeriod, thisPeriod } = range

      const { start: lastPeriodStartDate, end: lastPeriodEndDate } = lastPeriod
      const { start: thisPeriodStartDate, end: thisPeriodEndDate } = thisPeriod

      const allResults = [
        this.lastPeriodStartDate === lastPeriodStartDate.format(DATE_FORMAT),
        this.lastPeriodEndDate === lastPeriodEndDate.format(DATE_FORMAT),
        this.thisPeriodStartDate === thisPeriodStartDate.format(DATE_FORMAT),
        this.thisPeriodEndDate === thisPeriodEndDate.format(DATE_FORMAT),
      ]

      return allResults.every((result) => result === true)
    },
    onChangeLastPeriodDateRange({ value }) {
      const { startDate, endDate } = value
      const dateRange = { minDate: startDate, maxDate: endDate }

      this.lastPeriodStartDate = startDate
      this.lastPeriodEndDate = endDate
      this.showLastPeriodDateRangePicker = false

      const period = 'lastPeriod'
      this.$emit('change:period-date-range', { value: { period, dateRange } })
    },
    onChangeThisPeriodDateRange({ value }) {
      const { startDate, endDate } = value
      const dateRange = { minDate: startDate, maxDate: endDate }

      this.thisPeriodStartDate = startDate
      this.thisPeriodEndDate = endDate
      this.showThisPeriodDateRangePicker = false

      const period = 'thisPeriod'
      this.$emit('change:period-date-range', { value: { period, dateRange } })
    },
    toggleLastPeriodDateRangePicker() {
      this.showThisPeriodDateRangePicker = false
      this.showLastPeriodDateRangePicker = !this.showLastPeriodDateRangePicker
    },
    toggleThisPeriodDateRangePicker() {
      this.showLastPeriodDateRangePicker = false
      this.showThisPeriodDateRangePicker = !this.showThisPeriodDateRangePicker
    },
    onClickQuickDateSelector(value) {
      const { lastPeriod, thisPeriod } = value
      const { start: lastPeriodStart, end: lastPeriodEnd } = lastPeriod
      const { start: thisPeriodStart, end: thisPeriodEnd } = thisPeriod

      this.lastPeriodStartDate = lastPeriodStart.format(DATE_FORMAT)
      this.lastPeriodEndDate = lastPeriodEnd.format(DATE_FORMAT)

      this.thisPeriodStartDate = thisPeriodStart.format(DATE_FORMAT)
      this.thisPeriodEndDate = thisPeriodEnd.format(DATE_FORMAT)

      this.$emit('change:period-date-range', {
        value: {
          period: 'lastPeriod',
          dateRange: {
            minDate: this.lastPeriodStartDate,
            maxDate: this.lastPeriodEndDate,
          },
        },
      })

      this.$emit('change:period-date-range', {
        value: {
          period: 'thisPeriod',
          dateRange: {
            minDate: this.thisPeriodStartDate,
            maxDate: this.thisPeriodEndDate,
          },
        },
      })
    },
    getSemester() {
      const currentQuarter = this.$moment().quarter()
      const isFirstSemester = currentQuarter < 3

      const thisPeriod = {
        start: this.$moment().quarter(isFirstSemester ? 1 : 3).startOf('quarter'),
        end: this.$moment().quarter(isFirstSemester ? 2 : 4).endOf('quarter'),
      }

      const lastPeriod = {
        start: this.$moment().quarter(isFirstSemester ? 3 : 1).startOf('quarter'),
        end: this.$moment().quarter(isFirstSemester ? 4 : 2).endOf('quarter'),
      }

      if (isFirstSemester) {
        return {
          lastPeriod: {
            start: lastPeriod.start.subtract(1, 'year'), // go back to previous year
            end: lastPeriod.end.subtract(1, 'year'), // go back to previous year
          },
          thisPeriod,
        }
      }

      return {
        lastPeriod,
        thisPeriod,
      }
    },
    getRange({ amount = 1, unit }) {
      if (unit === 'semester') {
        return this.getSemester()
      }

      return {
        lastPeriod: {
          start: this.$moment().subtract(amount, unit).startOf(unit),
          end: this.$moment().subtract(amount, unit).endOf(unit),
        },
        thisPeriod: {
          start: this.$moment().startOf(unit),
          end: this.$moment().endOf(unit),
        },
      }
    },
    loadDefaultDates() {
      const { trendingDataDateRangeFilter } = this.appliedTrendingDataFilters
      const {
        lastPeriod: filterLastPeriodDates,
        thisPeriod: filterThisPeriodDates,
      } = trendingDataDateRangeFilter

      const {
        minDate: filterLastPeriodMinDate,
        maxDate: filterLastPeriodMaxDate,
      } = filterLastPeriodDates
      const {
        minDate: defaultLastPeriodMinDate,
        maxDate: defaultLastPeriodMaxDate,
      } = this.defaultLastPeriodDateRange

      this.lastPeriodStartDate = filterLastPeriodMinDate || defaultLastPeriodMinDate
      this.lastPeriodEndDate = filterLastPeriodMaxDate || defaultLastPeriodMaxDate

      const {
        minDate: filterThisPeriodMinDate,
        maxDate: filterThisPeriodMaxDate,
      } = filterThisPeriodDates
      const {
        minDate: defaultThisPeriodMinDate,
        maxDate: defaultThisPeriodMaxDate,
      } = this.defaultThisPeriodDateRange
      this.thisPeriodStartDate = filterThisPeriodMinDate || defaultThisPeriodMinDate
      this.thisPeriodEndDate = filterThisPeriodMaxDate || defaultThisPeriodMaxDate
    },
  },
}
</script>

<style scoped>
.trendingdata--trending--box {
  border: 1px solid var(--r-border-color);
  overflow: hidden;
  border-radius: 3px;
}
</style>
