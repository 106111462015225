import { COMPARISON_CHART_DATA_TYPES } from '@/utils/constants'

const buildComparisonChartData = ({ type, data }) => {
  if (!data || !data.length) {
    return {
      data: [],
      breakdownKeys: [],
    }
  }

  if (type === COMPARISON_CHART_DATA_TYPES.BREAKDOWN) {
    const breakdownData = data.map((datum) => ({
      value: Object.values(datum.volume),
      dateFrom: datum.dateFrom,
      dateTo: datum.dateTo,
    }))

    const breakdownKeys = Object.keys(data[0].volume).map((labelKey) => ({
      labelKey,
    }))

    return {
      data: breakdownData,
      breakdownKeys,
    }
  }

  const aggregateData = data.map((datum) => ({
    value: datum.volume,
    dateFrom: datum.dateFrom,
    dateTo: datum.dateTo,
  }))

  return {
    data: aggregateData,
  }
}

export default buildComparisonChartData
