<template>
  <div
    class="dashboardfilters"
    @submit.prevent="onClickApply"
  >
    <div class="dashboardfilters--fields">
      <v-row>
        <v-col cols="6">
          <p>
            {{ $t('dashboardFilters.sentiment') }}
          </p>
          <r-select
            :key="`sentiment-selector-${fetchingDashboardsData}`"
            class="sentiment-selector"
            :show-search="false"
            :items="sentimentItems"
            @change="onChangeSentiment"
          />
        </v-col>
        <v-col cols="6">
          <p>
            {{ $t('dashboardFilters.urls') }}
          </p>
          <r-select
            :key="`urls-selector-${fetchingDashboardsData}`"
            class="urls-selector"
            multiple
            :items="urlItems"
            @change="onChangeUrls"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p>{{ this.$t('dashboardFilters.starRating') }} </p>
          <r-rating-filter-selector
            :pre-selected-items="selectedRatingItems"
            @change="onChangeRatingFilterSelector"
          />
        </v-col>
        <v-col cols="6">
          <p>
            {{ $t('dashboardFilters.hostnames') }}
          </p>
          <r-select
            :key="`hostnames-selector-${fetchingDashboardsData}`"
            class="hostnames-selector"
            multiple
            :items="hostnameItems"
            @change="onChangeHostnames"
          />
          <p class="mt-4">
            {{ $t('dashboardFilters.entityMaps') }}
          </p>
          <r-select
            :key="`entitymaps-selector-${fetchingDashboardsData}`"
            class="entitymaps-selector"
            :items="entityMapsSelectorItems"
            @change="onChangeEntityMap"
          >
            <template v-slot:prepend-item>
              <v-col class="d-flex pa-0 rselectitem">
                <button
                  class="entitymaps-selector--create-new-entity-map-button rselectitem--row"
                  @click="onClickCreateNewEntityMap"
                >
                  <v-icon
                    dense
                    class="pl-2"
                  >
                    add
                  </v-icon>
                  <span>{{ $t('dashboardFilters.createEntityMap') }}</span>
                </button>
              </v-col>
            </template>
          </r-select>
        </v-col>
      </v-row>
    </div>
    <div class="dashboardfilters--actionbtns mt-2 d-flex justify-end align-center">
      <r-button
        class="mr-1"
        :label="$t('global.actions.cancel')"
        outlined
        @click="onClickCancel"
      />
      <r-button
        :label="$t('global.actions.apply')"
        type="submit"
        :loading="fetchingDashboardsData"
        @click="onClickApply"
      />
    </div>

    <create-entity-map-modal
      v-if="showCreateEntityModal"
      source="dashboard page"
      @close="onCloseCreateEntityMapModal"
      @created-new-entity-map="onNewEntityMapCreated"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RButton from '@/components/library/atoms/RButton'
import RSelect from '@/components/library/molecules/RSelect'
import RRatingFilterSelector from '@/components/library/atoms/RRatingFilterSelector'
import CreateEntityMapModal from '@/components/app/dashboards/CreateEntityMapModal'

import { SENTIMENT_RANGES } from '@/utils/constants'
import { track } from '@/tracking'

export default {
  name: 'DashboardFilters',
  components: {
    RButton,
    RSelect,
    CreateEntityMapModal,
    RRatingFilterSelector,
  },
  data: () => ({
    filters: {},
    showCreateEntityModal: false,
  }),
  computed: {
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
    ]),
    ...mapGetters('dashboardsData', [
      'urls',
      'hostnames',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    ...mapGetters('entityMaps', [
      'entityMapsItems',
      'appliedEntityMapId',
    ]),
    urlItems() {
      return this.urls.map(({ _id, url }) => {
        const isSelected = !!this.appliedFilters.urlIds?.includes(_id)
        return {
          label: url,
          value: _id,
          selected: isSelected,
        }
      })
    },
    hostnameItems() {
      return this.hostnames.map((hostname) => {
        const isSelected = !!this.appliedFilters.hostnames?.includes(hostname)
        return {
          label: hostname,
          value: hostname,
          selected: isSelected,
        }
      })
    },
    sentimentItems() {
      return Object.keys(SENTIMENT_RANGES).map((sentimentOption) => {
        const isSelected = this.appliedFilters.sentiment === sentimentOption
        return {
          label: this.$t(`dashboardFilters.sentimentOptions.${sentimentOption.toLowerCase()}`),
          value: sentimentOption,
          selected: isSelected,
        }
      })
    },
    selectedRatingItems() {
      return this.appliedFilters.ratings
    },
    entityMapsSelectorItems() {
      const selectedEntityMapId = this.filters.entityMapId || this.appliedEntityMapId

      return this.entityMapsItems(selectedEntityMapId)
    },
  },
  mounted() {
    this.fetchEntityMaps()
  },
  methods: {
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    ...mapActions('entityMaps', [
      'fetchEntityMaps',
      'fetchEntityMap',
    ]),
    onChangeSentiment(selectedItem) {
      if (!selectedItem) {
        this.$data.filters = ({ ...this.$data.filters, sentiment: null })
        return
      }

      const { value } = selectedItem
      this.$data.filters = ({ ...this.$data.filters, sentiment: value })
    },
    onChangeUrls({ value: selectedUrls }) {
      const urlIds = selectedUrls.map(({ value }) => value)
      this.$data.filters = ({ ...this.$data.filters, urlIds })
    },
    onChangeHostnames({ value: selectedHostnames }) {
      const hostnames = selectedHostnames.map(({ value }) => value)
      this.$data.filters = ({ ...this.$data.filters, hostnames })
    },
    onChangeRatingFilterSelector({ value }) {
      this.$data.filters = ({ ...this.$data.filters, ratings: value })
    },
    onChangeEntityMap({ value }) {
      this.$data.filters = ({ ...this.$data.filters, entityMapId: value })
    },
    async onClickApply() {
      const { filters } = this.$data

      await this.setDashboardFilters({ ...filters })
      if (filters?.entityMapId) {
        await this.fetchEntityMap(filters)
      }
      this.$emit('close')

      const ratingFiltersLength = filters?.ratings?.length ?? 0
      const isRatingsSelected = (ratingFiltersLength !== 5
        && ratingFiltersLength !== 0)
      const isSentimentSelected = !!filters?.sentiment
      const isUrlIdsSelected = !!filters?.urlIds?.length
      const isHostnamesSelected = !!filters?.hostnames?.length
      const isEntityMapIdSelected = !!filters?.entityMapId

      const isAtLeastOneFilterSelected = isRatingsSelected
        || isSentimentSelected
        || isUrlIdsSelected
        || isHostnamesSelected
        || isEntityMapIdSelected

      if (isAtLeastOneFilterSelected) {
        track('reviews dashboard filters - filters applied',
          {
            sentimentfilterselected: isSentimentSelected,
            starratingfilterselected: isRatingsSelected,
            urlsfilterselected: isUrlIdsSelected,
            domainsfilterselected: isHostnamesSelected,
            entitymapfilterselected: isEntityMapIdSelected,
          })
      }
    },
    onClickCancel() {
      this.$emit('close')
    },
    onClickCreateNewEntityMap() {
      track('reviews dashboard filter - create new entity map clicked')
      this.showCreateEntityModal = true
    },
    onCloseCreateEntityMapModal() {
      this.fetchEntityMaps()
      this.showCreateEntityModal = false
    },
    onNewEntityMapCreated({ value }) {
      const { _id: entityMapId } = value
      this.filters = {
        ...this.filters,
        entityMapId,
      }
      this.fetchEntityMaps()
    },
  },
}
</script>

<style scoped>
.dashboardfilters {
  width: 900px;
  padding: 15px 15px;
}
.entitymaps-selector--create-new-entity-map-button {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid var(--r-border-color);
}
.entitymaps-selector--create-new-entity-map-button span {
  margin-left: 19px;
  font-size: 12px;
  color: rgba(63, 63, 63, 0.75);
}
</style>
