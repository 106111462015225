<template>
  <r-modal
    class="savedashboardmodal"
    :title="$t('dashboards.save.title')"
    :close-action-label="$t('global.actions.cancel')"
    :action-label="$t('global.actions.save')"
    :fetching="fetchingSaveDashboard"
    :error-message="saveDashboardError"
    @close="onClose"
    @submit="onClickSave"
  >
    <template v-slot:content>
      <div class="d-flex justify-center align-center">
        {{ $t('dashboards.save.confirmMessage') }}
      </div>
    </template>
  </r-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RModal from '@/components/library/organisms/RModal'

export default {
  name: 'SaveDashboardModal',
  components: {
    RModal,
  },
  computed: mapState('dashboards', [
    'fetchingSaveDashboard',
    'saveDashboardError',
  ]),
  methods: {
    ...mapActions('dashboards', [
      'saveDashboard',
      'resetSaveDashboardState',
    ]),
    async onClickSave() {
      await this.saveDashboard()
      if (!this.saveDashboardError) {
        this.onClose()
      }
    },
    onClose() {
      this.resetSaveDashboardState()
      this.$emit('close')
    },
  },
}
</script>
