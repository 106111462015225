var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboarddateselector"
  }, [_c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeAllIsSelected,
      "label": "All"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange('all');
      }
    }
  }), _c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeIs60Days,
      "label": "60 days"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange(60);
      }
    }
  }), _c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeIs30Days,
      "label": "30 days"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange(30);
      }
    }
  }), _c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeIsTwoWeek,
      "label": "14 days"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange(14);
      }
    }
  }), _c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeIsOneWeek,
      "label": "7 days"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange(7);
      }
    }
  }), _c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "active": _vm.dateRangeIsToday,
      "label": "Today"
    },
    on: {
      "click": function () {
        return _vm.onClickQuickDateRange(1);
      }
    }
  }), _c('div', {
    staticClass: "d-inline position-relative daterange--selector"
  }, [_c('r-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": `${_vm.startDate} - ${_vm.endDate}`,
      "textonly": true,
      "icon": "expand_more"
    },
    on: {
      "click": _vm.toggleDateRangePicker
    }
  }), _vm.showDateRangePicker ? _c('r-date-range-picker', {
    attrs: {
      "active-quick-action": _vm.selectedDateRange,
      "range": [_vm.startDate, _vm.endDate]
    },
    on: {
      "cancel": function ($event) {
        _vm.showDateRangePicker = false;
      },
      "change": _vm.onChangeSelectedDateRange
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }