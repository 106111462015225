<template>
  <div class="trending--column px-4">
    <div class="d-flex justify-space-between">
      <r-big-number
        :title="$t('dashboards.components.totalNumReviewsTitle')"
        :value="numReviews"
        :loading="loading"
        :previous-value="0"
      />
      <r-average-star-rating
        :title="$t('dashboards.components.averageRating')"
        :value="netRating"
        :loading="loading"
        :is-half-incrementing="false"
        @click:rating="(payload) => this.$emit('click:rating', payload)"
      />
    </div>
    <div>
      <r-net-sentiment-half-donut
        :title="$t('dashboards.components.netSentimentDistribution')"
        :horizontal="true"
        :net-sentiment="netSentiment"
        :num-negative="sentimentBreakdown.negative"
        :num-neutral="sentimentBreakdown.neutral"
        :num-positive="sentimentBreakdown.positive"
        :loading="loading"
        @click:sentiment="(payload) => this.$emit('click:sentiment', payload)"
      />
    </div>
    <div>
      <r-topic-wheel
        :data="topicWheelItems"
        :hierarchical="false"
        :title="$t('dashboards.components.topicWheel')"
        :loading="loading"
        :skeleton-size="300"
        @click:entity-name="(payload) => this.$emit('click:entity-name', payload)"
      />
    </div>
    <div>
      <r-component-header>
        {{ $t('dashboards.comparisonTrendingData.allEntities') }}
      </r-component-header>
      <v-skeleton-loader
        v-if="loading"
        class="wordcloud-loader mx-auto"
        max-height="200px"
        max-width="400px"
        type="list"
      />
      <r-word-cloud
        v-else
        :entities="wordCloudItems"
        color="netSentiment"
        disable-entity-map-controls
        @click:word="(payload) => this.$emit('click:word', payload)"
      />
    </div>
  </div>
</template>

<script>
import RWordCloud from '@/components/library/organisms/RWordCloud'
import RBigNumber from '@/components/library/molecules/RBigNumber'
import RTopicWheel from '@/components/library/molecules/RTopicWheel'
import RComponentHeader from '@/components/library/atoms/RComponentHeader'
import RAverageStarRating from '@/components/library/molecules/RAverageStarRating'
import RNetSentimentHalfDonut from '@/components/library/molecules/RNetSentimentHalfDonut'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TrendingColumn',
  components: {
    RWordCloud,
    RBigNumber,
    RTopicWheel,
    RComponentHeader,
    RAverageStarRating,
    RNetSentimentHalfDonut,
  },
  props: {
    numReviews: {
      type: Number,
      required: true,
    },
    netRating: {
      type: Number,
      required: true,
      validator: (value) => (value >= 0 && value <= 5),
    },
    netSentiment: {
      type: Number,
      required: true,
    },
    sentimentBreakdown: {
      type: Object,
      require: false,
    },
    entities: {
      type: Array,
      require: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    topicWheelItems() {
      if (!this.entities || !this.entities.length) {
        return []
      }

      const limit = 10
      const limitedItems = this.entities.slice(0, limit)

      return limitedItems.map((item) => ({
        label: item.entity,
        value: item.numReviews,
        data: [],
      }))
    },
    wordCloudItems() {
      return this.entities.map((item) => ({
        ...item,
        _id: uuid(),
        label: item.entity,
        volume: item.numReviews,
        rating: item.netRating,
        isEntityGroup: Object.hasOwn(item, 'entityMapId') && Object.hasOwn(item, 'entityGroupId'),
      }))
    },
  },
}
</script>
