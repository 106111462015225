var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "keycolor d-flex justify-center mt-10"
  }, _vm._l(_vm.appliedColorMap, function (_ref) {
    var key = _ref[0],
      color = _ref[1];
    return _c('div', {
      key: key,
      staticClass: "keycolor-legend d-flex mr-4"
    }, [_c('div', {
      staticClass: "circle mr-2",
      style: {
        backgroundColor: color
      }
    }), _c('div', {
      style: {
        color: color
      }
    }, [_vm._v(" " + _vm._s(_vm.hasTranslation ? _vm.$t(`global.keys.${key.toLowerCase()}`) : key) + " ")])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }