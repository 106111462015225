<template>
  <div class="productselectionpopup pa-4 pt-0">
    <div class="d-flex align-center mb-4">
      <span class="mr-2">Sort by</span>
      <r-select
        :items="sortOptions"
        :show-search="false"
        style="width: 226px"
        @change="onSortByChanged"
      />
    </div>
    <v-row class="ma-0 mb-4">
      <r-search-input
        :value="search"
        placeholder="Search"
        @change="onChangeSearch"
      />
    </v-row>
    <r-error-message
      :errors="errors"
      class="mb-4"
    />
    <r-table
      class="productstable--table"
      :columns="tableColumns"
      :rows="productItems"
      :total-rows="totalProducts"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingProducts"
      @change:page="({ value }) => setPage({ value })"
      @change:page-size="onChangePageSize"
      @change:batch-select="onBatchSelect"
      @change:batch-select-all="onBatchSelectAll"
    >
      <template v-slot:item.name="{ item }">
        <span
          class="productstable--name text-truncate"
          :title="item.name"
        >
          {{ item.name }}
        </span>
      </template>
      <template v-slot:item.averageRating="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ parseFloat(item.averageRating.toFixed(1)) }}</span>
          <v-rating
            v-model="item.averageRating"
            length="1"
            color="yellow darken-2"
            background-color="yellow darken-2"
          />
        </div>
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
        </div>
      </template>
    </r-table>
    <div class="d-flex mt-4 justify-end productsselectionpopup--button--container">
      <r-button
        class="productsselectionpopup--button"
        style="width: 123px"
        :outlined="true"
        @click="$emit('onClose')"
      >
        Cancel
      </r-button>
      <r-button
        :disabled="hasErrors"
        class="productsselectionpopup--button"
        style="width: 123px"
        @click="onClickApply"
      >
        Apply
      </r-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect.vue'
import RSearchInput from '@/components/library/molecules/RSearchInput.vue'
import RErrorMessage from '@/components/library/atoms/RErrorMessage.vue'
import RButton from '@/components/library/atoms/RButton.vue'
import RTable from '@/components/library/organisms/RTable.vue'
import debounce from '@/utils/debounce'
import { getProducts } from '@/api/dashboardsData'
import { MULTI_PRODUCTS_DASHBOARD_MAX_PRODUCT_SELECT } from '@/utils/constants'

const SORT_OPTIONS = [
  { label: 'A to Z ', value: 'name_asc', selected: true },
  { label: 'Z to A', value: 'name_desc', selected: false },
  { label: 'Highest rating', value: 'averageRating_desc', selected: false },
  { label: 'Lowest rating', value: 'averageRating_asc', selected: false },
  { label: 'Highest reviewed', value: 'numReviews_desc', selected: false },
  { label: 'Lowest reviewed', value: 'numReviews_asc', selected: false },
  { label: 'Highest sentiment score', value: 'averageSentiment_desc', selected: false },
  { label: 'Lowest sentiment score', value: 'averageSentiment_asc', selected: false },
]

export default {
  name: 'ProductSelectionPopup',
  components: {
    RTable,
    RButton,
    RErrorMessage,
    RSearchInput,
    RSelect,
  },
  props: {
    preselectedSort: {
      type: String,
      default: 'name',
      required: false,
    },
    preselectedSortDir: {
      type: String,
      default: 'asc',
      required: false,
    },
    hasMaxProductSelectionLimit: {
      type: Boolean,
      default: false,
    },
    preselectedProductIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Product Name',
        hideable: false,
        sortable: false,
        width: 210,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: false,
        sortable: false,
        width: 80,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: false,
        sortable: false,
        width: 100,
      },
    ],
    search: '',
    sort: 'name',
    sortDir: 'asc',
    page: 1,
    pageSize: 50,
    debouncedFetchData: null,
    products: [],
    fetchingProductsError: null,
    fetchingProducts: false,
    totalProducts: 0,
    allProductsSelected: false,
    selectionLimitError: '',
    productItems: [],
    preSelectedProductIds: [],
    selectedProductsPerPage: {},
  }),
  computed: {
    ...mapGetters('dashboardsData', [
      'dashboardId',
    ]),
    sortOptions() {
      const selectedValue = `${this.sort}_${this.sortDir}`
      return SORT_OPTIONS.map((sortOption) => ({
        ...sortOption,
        selected: sortOption.value === selectedValue,
      }))
    },
    errors() {
      return [this.fetchingProductsError, this.selectionLimitError]
    },
    hasErrors() {
      return this.errors.filter((error) => !!error).length > 0
    },
    selectedProductsCount() {
      if (this.allProductsSelected) {
        return this.$data.totalProducts
      }

      return this.allPageSelectedProductIds.length
    },
    isOverSelectionQuota() {
      return this.selectedProductsCount > MULTI_PRODUCTS_DASHBOARD_MAX_PRODUCT_SELECT
    },
    allPageSelectedProductIds() {
      const uniqueSelectedProducts = Object.values(this.$data.selectedProductsPerPage)
        .flat()
        .map(({ _id }) => _id)

      return [...new Set(uniqueSelectedProducts)]
    },
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.page = 1
      this.$data.debouncedFetchData()
    },
    search() {
      this.page = 1
      this.$data.debouncedFetchData()
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    selectedProductsPerPage: {
      handler() {
        this.loadSelectionLimitError()
      },
      deep: true,
    },
    products() {
      this.loadProducts()
    },
  },
  async beforeMount() {
    this.sort = this.preselectedSort
    this.sortDir = this.preselectedSortDir
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.$data.preSelectedProductIds = this.$props.preselectedProductIds
    await this.$data.debouncedFetchData()
  },
  methods: {
    async fetchData() {
      this.fetchingProductsError = ''
      this.fetchingProducts = true

      try {
        const { products, totalRecords } = await getProducts({
          dashboardId: this.dashboardId,
          page: this.page,
          pageSize: this.pageSize,
          search: this.search,
          sort: this.sort,
          sortDir: this.sortDir,
        })

        this.products = products
        this.totalProducts = totalRecords
        this.fetchingProducts = false
        this.fetchingProductsError = ''
      } catch (error) {
        this.fetchingProductsError = error.message
        this.fetchingProducts = false
      }
    },
    setPage({ value }) {
      this.page = value
    },
    onChangePageSize({ value }) {
      this.pageSize = value
    },
    onChangeSearch({ value }) {
      this.search = value
    },
    onSortByChanged({ value }) {
      const [sort, sortDir] = value.split('_')
      this.sort = sort
      this.sortDir = sortDir
    },
    onClickApply() {
      const {
        allProductsSelected,
        sortDir,
        search,
        sort,
      } = this

      const basePayload = {
        search,
        sort,
        sortDir,
      }

      let products = null
      if (!allProductsSelected) {
        products = Object.values(this.selectedProductsPerPage)
          .flat()
          .map(({ _id }) => ({ _id }))
          .reduce((acc, currentValue) => {
            const existingItem = acc.find((item) => item._id === currentValue._id)
            if (existingItem) {
              return acc
            }
            return [...acc, currentValue]
          }, [])
      }

      const payload = {
        ...basePayload,
        value: products,
      }

      this.$emit('change:selected-products', { ...payload })
      this.$emit('onClose')
    },
    updateLocalPreSelectedProductIds({ selectedItems, clearAllPages = false }) {
      if (clearAllPages && !selectedItems.length) {
        this.selectedProductsPerPage = {}
        this.$data.preSelectedProductIds = []
        return
      }

      const currentSelectedItem = this.selectedProductsPerPage[this.page] || []
      const currentSelectedItemIds = currentSelectedItem.map(({ _id }) => _id)
      const selectedItemIds = selectedItems.map(({ _id }) => _id)

      const unselectedItemIds = currentSelectedItemIds.filter((id) => !selectedItemIds.includes(id))
      this.$data.preSelectedProductIds = this.$data.preSelectedProductIds
        .filter((id) => !unselectedItemIds.includes(id))
    },
    onBatchSelect({ value: selectedItems }) {
      this.updateLocalPreSelectedProductIds({
        selectedItems,
        clearAllPages: this.allProductsSelected,
      })

      this.selectionLimitError = ''
      this.$set(this.selectedProductsPerPage, this.page, selectedItems)
      this.allProductsSelected = false
    },
    onBatchSelectAll({ value }) {
      this.selectionLimitError = ''
      this.selectedProductsPerPage = {}
      this.allProductsSelected = value
    },
    loadSelectionLimitError() {
      if (!this.selectedProductsCount && this.products.length) {
        this.selectionLimitError = 'Please select at least 1 product'
        return
      }

      if (this.hasMaxProductSelectionLimit && this.isOverSelectionQuota) {
        this.selectionLimitError = `You can only select up to ${MULTI_PRODUCTS_DASHBOARD_MAX_PRODUCT_SELECT} products`
        return
      }

      this.selectionLimitError = ''
    },
    isProductSelected(productId) {
      return this.allPageSelectedProductIds.includes(productId)
    },
    loadSelectedProductsPerPage() {
      // load preselected products of page 1, or already selected product
      const currentPagePreSelectedProducts = this.products
        .filter(({ _id }) => this.$data.preSelectedProductIds.includes(_id) || this.isProductSelected(_id)) // eslint-disable-line max-len
        .map(({ _id }) => ({ _id }))
      this.$set(this.selectedProductsPerPage, this.page, currentPagePreSelectedProducts)

      // loaded preselected product for next pages
      const productIds = this.products.map(({ _id }) => _id)
      const selectedProductsFromNextPages = this.$data.preSelectedProductIds
        .filter((preselectedProductId) => !productIds.includes(preselectedProductId))
        .map((productId) => ({ _id: productId }))
      this.$set(this.selectedProductsPerPage, 0, selectedProductsFromNextPages)
    },
    loadProducts() {
      this.loadSelectedProductsPerPage()

      this.$data.productItems = this.products.map((product) => ({
        ...product,
        selected: this.isProductSelected(product._id),
      }))
    },
  },
}
</script>

<style scoped>
.productselectionpopup {
  min-width: 500px;
}
.productstable--name {
  display: inherit;
  width: inherit;
}
.productsselectionpopup--button {
  width: 123px;
}
.productsselectionpopup--button--container {
  gap: 8px;
}
.productstable--table {
  height: fit-content !important;
}
/deep/.productstable--table table {
  height: fit-content !important;
}
/deep/.productstable--table table tbody {
  max-height: 400px;
  height: fit-content !important;
}
</style>
