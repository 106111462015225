<template>
  <div class="rratingfilterselector">
    <v-row>
      <v-col
        v-for="(rating, index) in ratingItems"
        :key="`rating-${rating}`"
        cols="12"
        class="pa-0 ma-0 pl-3"
      >
        <v-checkbox
          v-model="selectedRatings[index]"
          dense
          class="ma-0 pa-0 checkbox-rating"
          @change="onChangeSelectedRatings"
        >
          <template
            v-slot:label
            class="ma-0 pa-0"
          >
            <r-rating :value="rating" />
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RRating from '@/components/library/atoms/RRating'

export default {
  name: 'RRatingFilterSelector',
  components: {
    RRating,
  },
  props: {
    preSelectedItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedRatings: [true, true, true, true, true],
  }),
  computed: {
    ratingItems() {
      return [5, 4, 3, 2, 1]
    },
  },
  mounted() {
    this.preSelectItems()
  },
  methods: {
    onChangeSelectedRatings() {
      const selectedItems = this.ratingItems.filter((item, index) => this.selectedRatings[index])
      this.$emit('change', { value: selectedItems })
    },
    preSelectItems() {
      if (!this.preSelectedItems.length) {
        this.selectedRatings = [true, true, true, true, true]
        return
      }

      this.selectedRatings = this.ratingItems.map((item) => this.preSelectedItems.includes(item))
    },
  },
}
</script>

<style scoped>

</style>
