<template>
  <div class="singleproductdashboard">
    <div class="pa-0">
      <div class="px-14">
        <!-- Header and Tabs -->
        <div class="header">
          <div class="py-6">
            <dashboard-header
              class="singleproductdashboard--header"
              :pinned="pinned"
              :name="name"
              :show-reviews="showReviews"
              @show:reviews="onShowReviews"
            />
          </div>

          <v-row class="singleproductdashboard--tabs ma-0 mt-2">
            <v-col class="pa-0">
              <r-grouped-tabs
                :selected-group-tab="selectedGroupTab"
                :selected-sub-tab="selectedSubTab"
                :items="SINGLE_PRODUCT_GROUPED_TABS"
                @change="onChangeGroupTab"
                @change:sub-tab="onChangeSubTab"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Filter and Chips -->
        <v-row
          v-if="!fetchingDashboardsData"
          class="singleproductdashboard--filter-row mx-0"
        >
          <v-col
            class="pa-0 mb-3"
            cols="6"
          >
            <dashboard-date-selector
              class="d-flex"
              :start-date="startDate"
              :end-date="endDate"
              @change="onChangeDateRange"
            />
          </v-col>
          <v-col
            class="pa-0"
            cols="6"
          >
            <dashboard-filter-chips class="d-flex flex-wrap flex-row-reverse" />
          </v-col>
        </v-row>
        <v-row
          v-else
          class="singleproductdashboard--filter-row mx-0"
        >
          <v-skeleton-loader
            class="elevation-0 filterrow--loader"
            type="list-item"
            height="35"
            width="100%"
          />
        </v-row>
      </div>

      <v-divider />
    </div>

    <single-product-dashboard-content
      ref="dashboardcontent"
      :selected-group-tab="selectedGroupTab"
      :selected-sub-tab="selectedSubTab"
      :show-reviews="showReviews"
      @on:show-reviews="onShowReviews"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DashboardHeader from '@/components/app/dashboards/common/DashboardHeader'
import RGroupedTabs from '@/components/library/molecules/RGroupedTabs'
import DashboardFilterChips from '@/components/app/dashboards/DashboardFilterChips'
import DashboardDateSelector from '@/components/app/dashboards/DashboardDateSelector'
import SingleProductDashboardContent from '@/components/app/dashboards/SingleProductDashboardContent.vue'
import { SCROLL_RESET_POSITION, SINGLE_PRODUCT_GROUPED_TABS } from '@/utils/constants'

export default {
  name: 'SingleProductDashboard',
  components: {
    SingleProductDashboardContent,
    DashboardDateSelector,
    DashboardHeader,
    RGroupedTabs,
    DashboardFilterChips,
  },
  data: () => ({
    selectedGroupTab: 0,
    selectedSubTab: 0,
    showReviews: false,
    SINGLE_PRODUCT_GROUPED_TABS,
    startDate: '',
    endDate: '',
  }),
  computed: {
    ...mapGetters('dashboardsFilters', [
      'dateRangeAllIsSelected',
      'dateRange',
      'appliedFilters',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
    ]),
    ...mapGetters('dashboardsData', [
      'pinned',
      'name',
      'dashboardDataStartDate',
      'dashboardDataEndDate',
      'dashboardId',
    ]),
  },
  watch: {
    dateRange() {
      const { minDate, maxDate } = this.appliedFilters
      if (minDate && maxDate) {
        this.startDate = minDate
        this.endDate = maxDate
      }
    },
    dashboardDataStartDate() {
      if (this.dateRangeAllIsSelected) {
        this.startDate = this.dashboardDataStartDate
        this.endDate = this.dashboardDataEndDate
      }
    },
  },
  async mounted() {
    await this.fetchData()
    if (this.dateRangeAllIsSelected) {
      this.startDate = this.dashboardDataStartDate
      this.endDate = this.dashboardDataEndDate
    }
  },
  beforeDestroy() {
    this.resetState()
    window.removeEventListener('resize', this.setDimensions)
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchDashboardsData',
    ]),
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    ...mapActions('entityMaps', [
      'resetState',
    ]),
    onChangeGroupTab(index) {
      this.selectedGroupTab = index
      this.selectedSubTab = undefined
      this.$refs.dashboardcontent.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
    },
    onChangeSubTab(index) {
      this.selectedSubTab = index
      this.$refs.dashboardcontent.$refs.dashboardcontent.scrollTo(SCROLL_RESET_POSITION)
    },
    onShowReviews({ value }) {
      this.showReviews = value
    },
    onChangeDateRange({ dateRange }) {
      this.setDashboardFilters({ dateRange })

      if (!dateRange) {
        return
      }

      const [startDate, endDate] = dateRange
      this.startDate = startDate
      this.endDate = endDate
    },
    async fetchData() {
      await this.fetchDashboardsData({ dashboardId: this.dashboardId })
    },
  },
}
</script>

<style scoped>
.singleproductdashboard--tabs {
  margin-top: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--r-border-color);
}
.singleproductdashboard--filter-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

/deep/ .rpageheader {
  border-bottom: 0;
}
/deep/ .v-skeleton-loader__image {
  height: 1000px;
}
/deep/ .singleproductdashboard--tabs .v-skeleton-loader__button  {
  height: 28px;
}
/deep/ .singleproductdashboard--filter-row .v-skeleton-loader__button,
/deep/ .singleproductdashboard--filter-row .v-skeleton-loader__chip {
  height: 24px;
}

.singleproductdashboard--date-range > div {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}
.singleproductdashboard--date-range i {
  font-weight: 700;
  margin-left: 10px;
}
.position-relative {
  position: relative;
}
.singleproductdashboard--trendingfilters {
  background-color: var(--soft-grey-color);
}
</style>
