<template>
  <div class="dashboarddateselector">
    <r-chip
      :active="dateRangeAllIsSelected"
      class="mr-2"
      label="All"
      @click="() => onClickQuickDateRange('all')"
    />
    <r-chip
      :active="dateRangeIs60Days"
      class="mr-2"
      label="60 days"
      @click="() => onClickQuickDateRange(60)"
    />
    <r-chip
      :active="dateRangeIs30Days"
      class="mr-2"
      label="30 days"
      @click="() => onClickQuickDateRange(30)"
    />
    <r-chip
      :active="dateRangeIsTwoWeek"
      class="mr-2"
      label="14 days"
      @click="() => onClickQuickDateRange(14)"
    />
    <r-chip
      :active="dateRangeIsOneWeek"
      class="mr-2"
      label="7 days"
      @click="() => onClickQuickDateRange(7)"
    />
    <r-chip
      :active="dateRangeIsToday"
      class="mr-2"
      label="Today"
      @click="() => onClickQuickDateRange(1)"
    />
    <div class="d-inline position-relative daterange--selector">
      <r-chip
        class="mr-2 "
        :label="`${startDate} - ${endDate}`"
        :textonly="true"
        icon="expand_more"
        @click="toggleDateRangePicker"
      />
      <r-date-range-picker
        v-if="showDateRangePicker"
        :active-quick-action="selectedDateRange"
        :range="[startDate, endDate]"
        @cancel="showDateRangePicker = false"
        @change="onChangeSelectedDateRange"
      />
    </div>
  </div>
</template>

<script>
import RChip from '@/components/library/atoms/RChip'
import RDateRangePicker from '@/components/library/molecules/RDateRangePicker'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardDateSelector',
  components: {
    RChip,
    RDateRangePicker,
  },
  props: {
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    showDateRangePicker: false,
  }),
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
      'dateRangeIsToday',
      'dateRangeIsOneWeek',
      'dateRangeIsTwoWeek',
      'dateRangeIs30Days',
      'dateRangeIs60Days',
      'dateRangeAllIsSelected',
    ]),
    selectedDateRange() {
      const { minDate, maxDate } = this.appliedFilters
      if (!minDate || !maxDate) {
        return undefined
      }

      return this.$moment(maxDate).diff(minDate, 'days')
    },
  },
  methods: {
    toggleDateRangePicker() {
      this.showDateRangePicker = !this.showDateRangePicker
    },
    onClickQuickDateRange(date) {
      if (date === 'all') {
        return this.$emit('change', { dateRange: null })
      }

      const startDate = this.$moment().subtract((date - 1), 'days').format('YYYY-MM-DD')
      const endDate = this.$moment().format('YYYY-MM-DD')
      return this.$emit('change', { dateRange: [startDate, endDate] })
    },
    onChangeSelectedDateRange({ value }) {
      const { startDate, endDate } = value

      this.$emit('change', { dateRange: [startDate, endDate] })

      this.showDateRangePicker = false
    },
  },
}
</script>
