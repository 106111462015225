<template>
  <div>
    <v-progress-linear
      v-if="fetching"
      class="dashboard--loadingindicator"
      indeterminate
    />
    <r-error-message
      v-else-if="fetchError"
      :errors="[$t('dashboards.detail.couldNotLoadDashboard', { error: fetchError })]"
      class="ma-4 dashboard--loadingerror"
    />

    <single-product-dashboard v-if="isSingleProduct" />
    <single-category-dashboard v-else-if="isSingleCategory" />
    <single-tag-dashboard v-else-if="isSingleTag" />
    <single-brand-dashboard v-else-if="isSingleBrand" />
    <multi-product-dashboard v-else-if="isMultiProduct" />
    <r-error-message
      v-else-if="unknownDashboardTypeError"
      :errors="[unknownDashboardTypeError]"
      class="ma-4 dashboard--dashboardtypeerror"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SingleProductDashboard from '@/components/app/dashboards/SingleProductDashboard'
import SingleCategoryDashboard from '@/components/app/dashboards/SingleCategoryDashboard'
import SingleTagDashboard from '@/components/app/dashboards/SingleTagDashboard'
import SingleBrandDashboard from '@/components/app/dashboards/SingleBrandDashboard'
import MultiProductDashboard from '@/components/app/dashboards/MultiProductDashboard'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import cleanFilters from '@/utils/cleanFilters'
import getSentimentRangeKey from '@/utils/getSentimentRangeKey'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'

const formatDate = (d) => d.split('T')[0]

const getApplicableFilters = (dashboardFilters) => {
  let filters = cleanFilters(dashboardFilters)
  const {
    minDate, maxDate, minSentiment, maxSentiment, trendingDataDateRangeFilter,
  } = filters
  if (minDate && maxDate) {
    filters = { ...filters, dateRange: [formatDate(minDate), formatDate(maxDate)] }
  }
  if (minSentiment && maxSentiment) {
    filters = { ...filters, sentiment: getSentimentRangeKey({ minSentiment, maxSentiment }) }
  }

  if (trendingDataDateRangeFilter) {
    const { thisPeriod, lastPeriod } = trendingDataDateRangeFilter
    if (thisPeriod?.minDate && thisPeriod?.maxDate) {
      const fixedDate = {
        minDate: formatDate(thisPeriod.minDate),
        maxDate: formatDate(thisPeriod.maxDate),
      }

      filters = {
        ...filters,
        trendingDataDateRangeFilter: {
          thisPeriod: fixedDate,
        },
      }
    }

    if (lastPeriod?.minDate && lastPeriod?.maxDate) {
      const fixedDate = {
        minDate: formatDate(lastPeriod.minDate),
        maxDate: formatDate(lastPeriod.maxDate),
      }

      filters = {
        ...filters,
        trendingDataDateRangeFilter: {
          ...filters.trendingDataDateRangeFilter,
          lastPeriod: fixedDate,
        },
      }
    }
  }

  return filters
}

export default {
  name: 'Dashboard',
  components: {
    SingleProductDashboard,
    SingleCategoryDashboard,
    SingleTagDashboard,
    SingleBrandDashboard,
    MultiProductDashboard,
    RErrorMessage,
  },
  props: {
    dashboardId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    filtersRestored: false,
  }),
  computed: {
    ...mapState('dashboardsData', [
      'activeDashboard',
      'fetchingDashboard',
      'fetchDashboardError',
    ]),
    ...mapState('entityMaps', [
      'fetchingEntityMap',
      'fetchEntityMapError',
    ]),
    isSingleProduct() {
      return this.activeDashboard?.type === TYPES_OF_DASHBOARD.SINGLE_PRODUCT
    },
    isSingleCategory() {
      return this.activeDashboard?.type === TYPES_OF_DASHBOARD.SINGLE_CATEGORY
    },
    isSingleTag() {
      return this.activeDashboard?.type === TYPES_OF_DASHBOARD.SINGLE_TAG
    },
    isSingleBrand() {
      return this.activeDashboard?.type === TYPES_OF_DASHBOARD.SINGLE_BRAND
    },
    isMultiProduct() {
      return this.activeDashboard?.type === TYPES_OF_DASHBOARD.MULTI_PRODUCT
    },
    unknownDashboardTypeError() {
      if (!this.isSingleCategory
        && !this.isSingleTag
        && !this.isSingleBrand
        && !this.isSingleProduct
        && !this.isMultiProduct
        && !this.fetching
      ) {
        if (!this.activeDashboard) {
          return this.$t('dashboards.detail.unknownDashboardType')
        }
        const { type } = this.activeDashboard
        return this.$t('dashboards.detail.dashboardTypeNotRecognised', { type })
      }
      return ''
    },
    fetching() {
      return this.fetchingDashboard || this.fetchingEntityMap || !this.$data.filtersRestored
    },
    fetchError() {
      return this.fetchDashboardError || this.fetchEntityMapError
    },
  },
  async mounted() {
    const { dashboardId } = this.$props
    await this.fetchDashboard({ dashboardId })
    if (this.activeDashboard?.filters) {
      const { filters } = this.activeDashboard
      this.setDashboardFilters(getApplicableFilters(filters))
      if (filters?.entityMapId) {
        await this.fetchEntityMap(filters)
      }
    }
    this.$data.filtersRestored = true
  },
  beforeDestroy() {
    this.resetDashboardDataState()
    this.resetFilters()
  },
  methods: {
    ...mapActions('dashboardsData', {
      fetchDashboard: 'fetchDashboard',
      resetDashboardDataState: 'resetState',
    }),
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
      'filterTrendingData',
      'resetFilters',
    ]),
    ...mapActions('entityMaps', [
      'fetchEntityMap',
    ]),
  },
}
</script>
