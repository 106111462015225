var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    ref: "dashboardcontent",
    staticClass: "dashboardcontent px-14 pt-6",
    attrs: {
      "cols": _vm.showReviews ? 9 : 12
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_vm.selectedGroupTab === 0 && _vm.selectedSubTab === 0 ? _c('v-col', {
    staticClass: "multiproductdashboard--tab--summary"
  }, [_c('v-row', {
    staticClass: "multiproductdashboard--summary ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "multiproductdashboard--summary--box pa-6"
  }, [_c('r-big-number', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "title": _vm.$t('dashboards.components.totalNumReviewsTitle'),
      "value": _vm.numReviews,
      "previous-value": 0
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-time-line-chart', {
    attrs: {
      "title": _vm.$t('dashboards.components.numReviewsOverTime'),
      "spline": "",
      "duration-gap": _vm.timeLineChartDurationGap,
      "data": [{
        chartType: 'column',
        seriesData: _vm.timeLineChartSeriesData
      }],
      "height": 120
    },
    on: {
      "click:point": _vm.onClickTimeLineChartPoint
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-rating-time-line-chart', {
    attrs: {
      "title": _vm.$t('dashboards.components.averageStarRatingOverTime'),
      "loading": _vm.fetchingDashboardsData,
      "max-rate-value": 5,
      "data": _vm.netRatingHistorySeriesData
    },
    on: {
      "click:rating": _vm.onClickRating
    }
  })], 1)]), _c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "multiproductdashboard--summary--box pa-6"
  }, [_c('r-star-rating-distribution-chart', {
    attrs: {
      "title": _vm.$t('dashboards.components.starRatingDistribution'),
      "loading": _vm.fetchingDashboardsData,
      "data": _vm.starRatingDistribution
    },
    on: {
      "click:rating": _vm.onClickRating
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-net-sentiment-half-donut', {
    attrs: {
      "title": _vm.$t('dashboards.components.netSentimentDistribution'),
      "loading": _vm.fetchingDashboardsData,
      "net-sentiment": _vm.netSentiment,
      "num-negative": _vm.sentimentBreakdown.negative,
      "num-neutral": _vm.sentimentBreakdown.neutral,
      "num-positive": _vm.sentimentBreakdown.positive
    },
    on: {
      "click:sentiment": _vm.onClickSentiment
    }
  })], 1)]), _c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "multiproductdashboard--summary--box pa-6"
  }, [_c('r-top-sentiment-review-cards', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "search": _vm.search,
      "applied-entities": _vm.appliedEntityFilters,
      "positive-review": _vm.topPositiveReview,
      "negative-review": _vm.topNegativeReview
    },
    on: {
      "click:see-all-reviews": function () {
        return _vm.onShowReviews({
          value: true
        });
      }
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-trends', {
    attrs: {
      "days": _vm.trendsSelectedDays,
      "loading": _vm.fetchingDashboardsData || _vm.fetchingTrends,
      "total-positive": _vm.trends.totalPositive,
      "total-negative": _vm.trends.totalNegative,
      "positive-percent-increase": _vm.trends.positivePercentIncrease,
      "negative-percent-increase": _vm.trends.negativePercentIncrease,
      "positive-data": _vm.trends.positiveData,
      "negative-data": _vm.trends.negativeData,
      "positive-entities": _vm.trends.positiveEntities,
      "negative-entities": _vm.trends.negativeEntities
    },
    on: {
      "change:daterange": _vm.handleTrendsDateChange,
      "click:entity-sentiment": _vm.onClickEntitySentiment,
      "click:entity": _vm.onClickEntity
    }
  })], 1)])], 1), _c('v-row', {
    staticClass: "multiproductdashboard--products-table px-5 py-10"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('products-table')], 1)], 1), _c('v-row', {
    staticClass: "multiproductdashboard--sites px-5 py-10"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-sites-analyzed-table', {
    attrs: {
      "data": _vm.sites,
      "loading": _vm.fetchingDashboardsData
    },
    on: {
      "click:hostname": _vm.onClickHostname
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "multiproductdashboard--product-sentiment-comparison px-5 py-10"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('product-sentiment-comparison')], 1)], 1), _c('v-row', {
    staticClass: "multiproductdashboard--product-history-comparison px-5 py-10"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('product-history-comparison')], 1)], 1)], 1) : _vm._e(), _vm.selectedGroupTab === 0 && _vm.selectedSubTab === 1 ? _c('v-col', {
    staticClass: "multiproductdashboard---tab--entity ma-0"
  }, [_c('v-row', {
    staticClass: "multiproductdashboard--top-entities"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('product-entities-comparison')], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showReviews,
      expression: "showReviews"
    }],
    staticClass: "reviewscardsection pa-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('r-review-card-list', {
    key: `review-card-list-${_vm.showReviews}`,
    attrs: {
      "reviews": _vm.reviews,
      "total-reviews": _vm.numReviews,
      "loading": _vm.fetchingReviews,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "total-pages": _vm.reviewsTotalPage,
      "search": _vm.appliedFilters.search,
      "applied-entities": _vm.appliedEntityFilters
    },
    on: {
      "change:page": _vm.onReviewsPageChange,
      "change:sort": _vm.onReviewsSortChange,
      "close": function () {
        return _vm.onShowReviews({
          value: false
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }