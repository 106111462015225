var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-modal', {
    staticClass: "createentitymapmodal",
    attrs: {
      "title": 'Create entity map',
      "max-content-height": 600
    },
    on: {
      "close": _vm.onClickCancel
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('create-edit-entity-map', {
          attrs: {
            "source": _vm.source,
            "entity-map-id": 'add',
            "redirect-after-save": false,
            "show-header-page": false
          },
          on: {
            "cancel": _vm.onClickCancel,
            "created-new-entity-map": _vm.onNewEntityMapCreated
          }
        })];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function () {
        return [_c('div')];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }