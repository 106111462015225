var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "createeditentitymap py-8"
  }, [_vm.showHeaderPage ? _c('r-page-header', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.isNewMap ? 'Create entity map' : 'Edit entity map',
      "previousroute": _vm.BACK_ROUTE
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function () {
        return [!_vm.isNewMap ? _c('r-button', {
          staticClass: "delete-button mr-2",
          attrs: {
            "label": "Delete entity map",
            "disabled": _vm.fetching,
            "outlined": ""
          },
          on: {
            "click": _vm.onClickDeleteMap
          }
        }) : _vm._e(), _c('r-button', {
          staticClass: "save--button",
          attrs: {
            "label": _vm.isNewMap ? 'Create entity map' : 'Save entity map',
            "loading": _vm.fetching
          },
          on: {
            "click": _vm.onClickSave
          }
        })];
      },
      proxy: true
    }], null, false, 629066051)
  }) : _vm._e(), _c('r-error-message', {
    key: `createeditentitymap--errormessage-${_vm.validationCount}`,
    attrs: {
      "errors": [_vm.errors]
    }
  }), _c('r-text-field', {
    ref: "entityMapName",
    staticClass: "entity-map-name--input mb-4 mt-8",
    attrs: {
      "label": "Entity map name",
      "placeholder": "Entity map name",
      "rules": [_vm.rules.required],
      "value": _vm.name
    },
    on: {
      "change": _vm.onChangeMapName
    }
  }), !_vm.isNewMap && _vm.entityMapDashboards.length ? [_c('label', [_vm._v("Dashboards it's applied to")]), _c('v-row', {
    staticClass: "createeditentitymap--dashboards ma-0 mt-4 mb-4"
  }, _vm._l(_vm.entityMapDashboards, function (dashboard) {
    return _c('r-dashboard-type-card', {
      key: `dashboard-type-card-${dashboard._id}`,
      attrs: {
        "type": dashboard.type,
        "label": dashboard.name
      }
    });
  }), 1)] : _vm._e(), _c('label', [_vm._v("Ignore list")]), _c('r-text-chips', {
    staticClass: "px-1 mb-12",
    attrs: {
      "disabled": _vm.addIgnoreListDisabled,
      "chips": _vm.ignore
    },
    on: {
      "add-chip": _vm.onAddIgnore,
      "remove-chip": _vm.onRemoveIgnore
    }
  }), _vm._l(_vm.groups, function (group) {
    return _c('edit-entity-group', {
      key: `edit-entity-group-${group._id}`,
      ref: "edit-entity-group",
      refInFor: true,
      staticClass: "mt-4",
      attrs: {
        "name": group.name,
        "entities": group.entities,
        "editing": group.editing,
        "groups": _vm.groups
      },
      on: {
        "change:name": function (_ref) {
          var value = _ref.value;
          return _vm.onChangeGroupName(group._id, value);
        },
        "change:entities": function (_ref2) {
          var value = _ref2.value;
          return _vm.onChangeGroupEntities(group._id, value);
        },
        "delete": function ($event) {
          return _vm.onDeleteGroup(group._id);
        }
      }
    });
  }), _c('div', {
    staticClass: "d-flex justify-end mt-8"
  }, [_c('v-tooltip', {
    attrs: {
      "disabled": !_vm.addEntityGroupDisabled,
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('r-button', {
          style: _vm.addGroupButtonDisabledStyle,
          attrs: {
            "attrs": attrs,
            "on": on,
            "label": "+ Add entity group",
            "outlined": ""
          },
          on: {
            "click": _vm.onClickAddGroup
          }
        })];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('entityMaps.maxGroupPerMap', {
    limit: _vm.ENTITYMAPS_LIMITS.GROUPS_PER_MAP
  })) + " ")])])], 1), !_vm.showHeaderPage ? _c('div', {
    staticClass: "d-flex mt-3 align-center justify-end"
  }, [_c('r-button', {
    staticClass: "cancel--button mr-2",
    attrs: {
      "outlined": true,
      "label": 'Cancel',
      "disabled": _vm.fetching
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    staticClass: "save--button",
    attrs: {
      "label": _vm.isNewMap ? 'Create entity map' : 'Save entity map',
      "loading": _vm.fetching
    },
    on: {
      "click": _vm.onClickSave
    }
  })], 1) : _vm._e(), _vm.confirmDelete ? _c('r-modal', {
    attrs: {
      "title": _vm.$t('entityMaps.deleteEntityMap.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('deleteConfirmation.delete'),
      "fetching": _vm.fetching
    },
    on: {
      "submit": _vm.onDelete,
      "close": function ($event) {
        _vm.confirmDelete = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchError]
          }
        }), _c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('entityMaps.deleteEntityMap.text')) + " ")])];
      },
      proxy: true
    }], null, false, 3751549885)
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }