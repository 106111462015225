<template>
  <r-modal
    class="editentitygroupmodal"
    :title="modalTitle"
    :close-action-label="$t('global.actions.cancel')"
    action-label="Add to entity map"
    :fetching="fetching"
    :action-disabled="!localEntities.length"
    :error-message="errorMessage"
    @close="onClickClose"
    @submit="onClickSubmit"
  >
    <template v-slot:content>
      <p class="mb-2">
        Entity
      </p>
      <r-text-field
        v-if="createNewGroup"
        ref="newgroupnamefield"
        :value="newGroupName"
        @change="({ value }) => newGroupName = value"
      />
      <r-select
        v-else
        label="Entity"
        class="editentitygroupmodal--selectgroup"
        :items="entityMapGroupItems(activeGroupId)"
        :append-items="[
          {
            label: 'Create a new entity',
            value: '_____NEW_ENTITY_____',
          }
        ]"
        :allow-empty="false"
        @click:append-item="onClickCreateNewEntity"
        @change="onChangeSelectedEntityGroup"
      />
      <p class="mt-4 mb-2">
        <b v-if="selectedGroupName">{{ selectedGroupName }}</b>
        <span v-else>The new entity group</span>
        will be
        <span v-if="createNewGroup">created, containing</span>
        <span v-else>updated to contain</span>
        the words
      </p>
      <r-text-chips
        class="pa-2 editentitygroupmodal--textchips"
        :chips="localEntities"
        :disabled="localEntities.length >= ENTITYMAPS_LIMITS.ENTITIES_PER_GROUP"
        @add-chip="onAddEntity"
        @remove-chip="onRemoveEntity"
      />
    </template>
  </r-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import RModal from '@/components/library/organisms/RModal'
import RTextField from '@/components/library/molecules/RTextField'
import RSelect from '@/components/library/molecules/RSelect'
import RTextChips from '@/components/library/molecules/RTextChips'
import { ENTITYMAPS_LIMITS } from '@/utils/constants'

export default {
  name: 'EditEntityGroupModal',
  components: {
    RModal,
    RTextField,
    RSelect,
    RTextChips,
  },
  props: {
    entityGroupId: {
      type: String,
      default: '',
    },
    entities: Array,
  },
  data: () => ({
    newGroupName: '',
    createNewGroup: false,
    selectedGroupId: null,
    localEntities: [],
    ENTITYMAPS_LIMITS,
  }),
  computed: {
    ...mapGetters('entityMaps', [
      'entityMapGroupItems',
      'entityMapGroups',
    ]),
    ...mapState('entityMaps', [
      'fetchCreateGroupError',
      'fetchEditGroupError',
    ]),
    activeGroupId() {
      if (this.$data.selectedGroupId) {
        return this.$data.selectedGroupId
      }
      if (this.$props.entityGroupId) {
        return this.$props.entityGroupId
      }
      const firstGroup = [...this.entityMapGroups()].shift()
      return firstGroup?._id
    },
    selectedGroup() {
      return this.entityMapGroups(this.activeGroupId)
    },
    selectedGroupName() {
      if (this.createNewGroup) {
        return this.newGroupName
      }
      return this.selectedGroup?.name
    },
    errorMessage() {
      return this.fetchCreateGroupError || this.fetchEditGroupError
    },
    fetching() {
      return this.fetchingCreateGroup || this.fetchingEditGroup
    },
    isCreateMode() {
      return this.createNewGroup
    },
    modalTitle() {
      if (this.isCreateMode) {
        return this.$t('dashboards.entityMaps.createEntityGroupTitle')
      }

      return this.$t('dashboards.entityMaps.editEntityGroupTitle')
    },
  },
  watch: {
    selectedGroup: {
      handler(_, previousGroup) {
        if (previousGroup) {
          const { entities: previousEntities } = this.entityMapGroups(previousGroup._id)
          previousEntities.forEach((value) => this.onRemoveEntity({ value }))
        }
        const newEntities = this.selectedGroup?.entities || []
        this.localEntities = [...new Set([...newEntities].concat(this.localEntities))]
      },
      immediate: true,
    },
    createNewGroup() {
      const { entities: previousEntities } = this.selectedGroup
      previousEntities.forEach((value) => this.onRemoveEntity({ value }))
    },
  },
  mounted() {
    (this.$props.entities || []).forEach((value) => this.onAddEntity({ value }))
  },
  methods: {
    ...mapActions('entityMaps', [
      'createEntityGroup',
      'editEntityGroup',
      'clearErrors',
    ]),
    async onClickSubmit() {
      const { localEntities: entities, createNewGroup } = this.$data
      if (createNewGroup) {
        const { newGroupName: name } = this.$data
        await this.createEntityGroup({ name, entities })
      } else {
        const { activeGroupId: entityGroupId } = this
        await this.editEntityGroup({ entityGroupId, entities })
      }
      if (!this.errorMessage) {
        this.$emit('submit')
        this.$emit('close')
        this.localEntities = []
      }
    },
    async onClickCreateNewEntity() {
      this.createNewGroup = true
      await this.$nextTick()
      const textFieldEl = this.$refs.newgroupnamefield.$el
      textFieldEl.querySelector('input').focus()
    },
    onChangeSelectedEntityGroup({ value }) {
      this.$data.selectedGroupId = value
    },
    onAddEntity({ value }) {
      this.$data.localEntities = [...new Set(
        this.$data.localEntities.concat(value),
      )]
    },
    onRemoveEntity({ value }) {
      this.$data.localEntities = this.$data.localEntities.filter((v) => v !== value)
    },
    onClickClose() {
      this.clearErrors({ fetchEditGroupError: '', fetchCreateGroupError: '' })
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
/deep/ .rsingleselect--appenditem .rselectitem {
  padding: 8px !important;
}
/deep/ .rsingleselect--appenditem .rselectitem--label {
  padding: 8px !important;
  background: var(--primary-color);
  color: white;
  font-weight: bold;
  text-align: center;
  user-select: none;
}
.editentitygroupmodal--textchips {
  border: 1px solid var(--light-grey-color);
  max-width: 538px;
}
</style>
