var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.fetching ? _c('v-progress-linear', {
    staticClass: "dashboard--loadingindicator",
    attrs: {
      "indeterminate": ""
    }
  }) : _vm.fetchError ? _c('r-error-message', {
    staticClass: "ma-4 dashboard--loadingerror",
    attrs: {
      "errors": [_vm.$t('dashboards.detail.couldNotLoadDashboard', {
        error: _vm.fetchError
      })]
    }
  }) : _vm._e(), _vm.isSingleProduct ? _c('single-product-dashboard') : _vm.isSingleCategory ? _c('single-category-dashboard') : _vm.isSingleTag ? _c('single-tag-dashboard') : _vm.isSingleBrand ? _c('single-brand-dashboard') : _vm.isMultiProduct ? _c('multi-product-dashboard') : _vm.unknownDashboardTypeError ? _c('r-error-message', {
    staticClass: "ma-4 dashboard--dashboardtypeerror",
    attrs: {
      "errors": [_vm.unknownDashboardTypeError]
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }