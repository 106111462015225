<template>
  <div
    class="keycolor d-flex justify-center mt-10"
  >
    <div
      v-for="([key, color]) of appliedColorMap"
      :key="key"
      class="keycolor-legend d-flex mr-4"
    >
      <div
        :style="{backgroundColor: color}"
        class="circle mr-2"
      />
      <div
        :style="{color: color}"
      >
        {{ hasTranslation ? $t(`global.keys.${key.toLowerCase()}`) : key }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KeyColor',
  props: {
    appliedColors: {},
    reversedKeys: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTranslation() {
      const { translate } = this.appliedColors
      return translate
    },
    appliedColorMap() {
      const { appliedColors, reversedKeys } = this.$props
      return reversedKeys
        ? Object.entries(appliedColors.colorMap).reverse()
        : Object.entries(appliedColors.colorMap)
    },
  },
}
</script>
<style scoped>
.singleproductdashboard--date-range > div {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}
.singleproductdashboard--date-range i {
  font-weight: 700;
  margin-left: 10px;
}
.keycolor-legend {
  align-items: center;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
</style>
