<template>
  <div class="entityprogresstimelinechart">
    <v-skeleton-loader
      v-if="loading"
      class="pa-1"
      type="image"
      :width="width"
      height="400"
    />
    <highcharts
      class="entityprogresstimelinechart--serieschart"
      :options="chartOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'EntityProgressTimeLineChart',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 900,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartOptions() {
      const series = this.data
      const ctx = this

      return {
        chart: {
          type: 'column',
          spacingTop: 5,
          spacingLeft: 0,
          spacingBottom: 0,
          spacingRight: 0,
          width: this.width,
        },
        yAxis: [
          {
            title: {
              text: 'Entities',
              enabled: true,
            },
          },
        ],
        xAxis: {
          type: 'datetime',
        },
        legend: {
          itemMarginTop: 10,
          itemMarginBottom: 40,
          symbolHeight: 8,
          symbolWidth: 8,
          symbolPadding: 10,
          itemStyle: {
            lineHeight: '20px',
            fontWeight: '400',
            color: 'rgba(63, 63, 63, 0.75)',
          },
        },
        tooltip: {
          enabled: true,
          headerFormat: '',
          pointFormat: '{point.y}',
          formatter() {
            const { series: thisSeries, point } = this
            const { name: seriesName } = thisSeries
            const { unitName } = point.series.chart.xAxis[0].tickPositions.info

            const date = unitName === 'month' ? ctx.$moment(this.x).format("MMM 'YY") : ctx.$moment(this.x).format("D. MMM 'YY")

            return `<span><b>${date}</b> <br/><b>${seriesName}</b>: ${this.y}</span>`
          },
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            pointPadding: 0,
            groupPadding: 0,
          },
          column: {
            pointWidth: 8,
            borderWidth: 0,
            stacking: 'normal',
            keys: ['x', 'y', 'name'],
          },
        },
        series,
      }
    },
  },
}
</script>
