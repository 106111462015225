<template>
  <v-row>
    <v-col
      v-for="(wordCloudGroup, index) of wordCloudGrouped"
      :key="index"
      :cols="getColumn(index)"
    >
      <v-row
        v-if="Object.keys(wordCloudGrouped).length !== 1 && getColumn(index) === 12"
        class="d-flex justify-center"
      >
        <v-col cols="6">
          <div
            v-if="appliedSeparateByFilter !== 'rating'"
            class="d-flex justify-center font-weight-bold"
          >
            {{ index }}
          </div>
          <r-rating
            v-else
            :value="parseInt(index, 10)"
            class="d-flex justify-center font-weight-bold mb-5"
          />
          <r-word-cloud
            :data-testid="`rwordcloud-${index}`"
            :common-hovered-word="hoveredWord"
            :applied-highlight-filter="hoveredWord ? null : appliedHighlightFilter"
            :color="appliedColorByFilter"
            :disable-entity-map-controls="!entityMapIsApplied"
            :entities="wordCloudGroup"
            :selected-words="allSelectedWords"
            :height="230"
            :loading-data="isGettingEntities"
            :time-buckets="timeBuckets"
            @change:selected-words="({ value }) => onChangeSelectedWords({ value, index })"
            @click:edit-entity-map-group="onClickEditEntityMapGroup"
            @click:word="onClickWord"
            @on:hover-over-entity="onHoverOverEntity"
            @on:hover-out-entity="onHoverOutEntity"
          />
        </v-col>
      </v-row>
      <template v-else>
        <div
          v-if="appliedSeparateByFilter !== 'rating'"
          class="d-flex justify-center font-weight-bold"
        >
          {{ index }}
        </div>
        <r-rating
          v-else
          :value="parseInt(index, 10)"
          class="d-flex justify-center font-weight-bold mb-5"
        />
        <r-word-cloud
          :data-testid="`rwordcloud-${index}`"
          :common-hovered-word="hoveredWord"
          :applied-highlight-filter="hoveredWord ? null : appliedHighlightFilter"
          :color="appliedColorByFilter"
          :disable-entity-map-controls="!entityMapIsApplied"
          :entities="wordCloudGroup"
          :selected-words="allSelectedWords"
          :height="230"
          :loading-data="isGettingEntities"
          :time-buckets="timeBuckets"
          @change:selected-words="({ value }) => onChangeSelectedWords({ value, index })"
          @click:edit-entity-map-group="onClickEditEntityMapGroup"
          @click:word="onClickWord"
          @on:hover-over-entity="onHoverOverEntity"
          @on:hover-out-entity="onHoverOutEntity"
        />
      </template>
    </v-col>
  </v-row>
</template>
<script>
import RRating from '@/components/library/atoms/RRating.vue'
import RWordCloud from '@/components/library/organisms/RWordCloud'

export default {
  name: 'RWordCloudGrouped',
  components: {
    RRating,
    RWordCloud,
  },
  props: {
    appliedSeparateByFilter: {
      type: String,
      validator: (value) => ['none', 'rating', 'netSentiment', 'time', 'url'].includes(value),
      default: 'none',
    },
    appliedHighlightFilter: {
      type: String,
      validator: (value) => ['none', 'commonWords', 'uniqueWords'].includes(value),
      default: 'none',
    },
    appliedColorByFilter: {
      type: String,
      validator: (value) => ['none', 'netSentiment', 'rating', 'time'].includes(value),
      default: 'none',
    },
    entityMapIsApplied: {
      type: Boolean,
      default: false,
    },
    isGettingEntities: {
      type: Boolean,
      default: false,
    },
    timeBuckets: {
      type: Array,
      default: () => [],
    },
    wordCloudGrouped: {},
  },
  data() {
    return {
      hoveredWord: '',
      groupedSelectedEntities: {},
      allSelectedWords: [],
    }
  },
  watch: {
    wordCloudGrouped() {
      this.groupedSelectedEntities = {}
      this.allSelectedWords = []
      this.$emit('change:selected-words', { value: [] })
    },
  },
  methods: {
    getIndexBy(index) {
      const indices = Object.keys(this.wordCloudGrouped)

      return indices.findIndex((ind) => ind === index)
    },
    getColumn(index) {
      const indices = Object.keys(this.wordCloudGrouped)
      const groupCount = indices.length

      const currentIndex = this.getIndexBy(index)
      if (groupCount === 1 || (groupCount > 1 && currentIndex === 2)) {
        return 12
      }

      return 6
    },
    onChangeSelectedWords({ value, index }) {
      this.groupedSelectedEntities[index] = value

      const selectedEntitiesSet = [
        ...new Set(Object.values(this.groupedSelectedEntities).flatMap((words) => words)),
      ]

      this.allSelectedWords = selectedEntitiesSet.map(({ label }) => label)

      this.$emit('change:selected-words', { value: selectedEntitiesSet })
    },
    onClickEditEntityMapGroup({ value }) {
      this.$emit('click:edit-entity-map-group', { value })
    },
    onClickWord({ value }) {
      this.$emit('click:word', { value })
    },
    onHoverOverEntity({ text }) {
      this.hoveredWord = text
    },
    onHoverOutEntity() {
      this.hoveredWord = ''
    },
  },
}
</script>
<style scoped>
.singleproductdashboard--date-range > div {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}

.singleproductdashboard--date-range i {
  font-weight: 700;
  margin-left: 10px;
}
</style>
