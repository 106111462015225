var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trending--column px-4"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('r-big-number', {
    attrs: {
      "title": _vm.$t('dashboards.components.totalNumReviewsTitle'),
      "value": _vm.numReviews,
      "loading": _vm.loading,
      "previous-value": 0
    }
  }), _c('r-average-star-rating', {
    attrs: {
      "title": _vm.$t('dashboards.components.averageRating'),
      "value": _vm.netRating,
      "loading": _vm.loading,
      "is-half-incrementing": false
    },
    on: {
      "click:rating": function (payload) {
        return _this.$emit('click:rating', payload);
      }
    }
  })], 1), _c('div', [_c('r-net-sentiment-half-donut', {
    attrs: {
      "title": _vm.$t('dashboards.components.netSentimentDistribution'),
      "horizontal": true,
      "net-sentiment": _vm.netSentiment,
      "num-negative": _vm.sentimentBreakdown.negative,
      "num-neutral": _vm.sentimentBreakdown.neutral,
      "num-positive": _vm.sentimentBreakdown.positive,
      "loading": _vm.loading
    },
    on: {
      "click:sentiment": function (payload) {
        return _this.$emit('click:sentiment', payload);
      }
    }
  })], 1), _c('div', [_c('r-topic-wheel', {
    attrs: {
      "data": _vm.topicWheelItems,
      "hierarchical": false,
      "title": _vm.$t('dashboards.components.topicWheel'),
      "loading": _vm.loading,
      "skeleton-size": 300
    },
    on: {
      "click:entity-name": function (payload) {
        return _this.$emit('click:entity-name', payload);
      }
    }
  })], 1), _c('div', [_c('r-component-header', [_vm._v(" " + _vm._s(_vm.$t('dashboards.comparisonTrendingData.allEntities')) + " ")]), _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "wordcloud-loader mx-auto",
    attrs: {
      "max-height": "200px",
      "max-width": "400px",
      "type": "list"
    }
  }) : _c('r-word-cloud', {
    attrs: {
      "entities": _vm.wordCloudItems,
      "color": "netSentiment",
      "disable-entity-map-controls": ""
    },
    on: {
      "click:word": function (payload) {
        return _this.$emit('click:word', payload);
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }