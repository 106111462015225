<template>
  <div class="producthistorycomparison">
    <v-row>
      <v-col cols="12">
        <r-tabs
          class=""
          :items="tabItems"
          :selected-tab="selectedTab"
          style="width:100%"
          @change="(i) => selectedTab = i"
        >
          <template v-slot:start>
            <span class="producthistorycomparison--tab-title px-1">
              Single view chart
            </span>
          </template>
        </r-tabs>
        <r-tab-items
          :selected-tab="selectedTab"
          class="mr-4 mt-4"
          style="width:100%; min-height: 730px"
        >
          <r-tab-item label="Volumen">
            <r-error-message
              class="my-2"
              :errors="[fetchProductsHistoryError]"
            />
            <template v-if="fetchingProductsHistory">
              <v-skeleton-loader
                type="image"
                width="100%"
                height="350"
              />
            </template>
            <template v-else>
              <highcharts
                ref="chart"
                class="hc"
                :options="chartOptions"
              />
            </template>
          </r-tab-item>
          <r-tab-item label="Rating">
            <r-error-message
              class="my-2"
              :errors="[fetchProductsHistoryError]"
            />
            <highcharts
              ref="chart"
              class="hc"
              :options="chartOptions"
            />
          </r-tab-item>
        </r-tab-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row class="d-flex justify-end align-center">
          <r-anchored-popup
            v-slot="{ onClose }"
            ref="producthistorycomparison-product-selector"
            label="Select Products"
          >
            <product-selection-popup
              :has-max-product-selection-limit="true"
              :preselected-sort="sort"
              :preselected-sort-dir="sortDir"
              :preselected-product-ids="selectedProductIds"
              @change:selected-products="onChangeSelectedProducts"
              @onClose="onClose"
            />
          </r-anchored-popup>
        </v-row>
        <product-card-selector
          :products="productsHistory"
          :selected-product-ids="highlightedItems"
          :hovered-product-id="hoveredItem"
          :loading="fetchingProductsHistory"
          @change:highlighted-product="onChangeHighlightedProduct"
          @change:hovered-product="onChangeHoveredProduct"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import RTabs from '@/components/library/molecules/RTabs'
import RTabItem from '@/components/library/molecules/RTabItem'
import RTabItems from '@/components/library/molecules/RTabItems'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import ProductCardSelector from '@/components/app/dashboards/common/ProductCardSelector'
import ProductSelectionPopup from '@/components/app/dashboards/common/ProductSelectionPopup'
import { getProductColorForNumber } from '@/utils/constants'
import dateStrToDate from '@/utils/dateStrToDate'

const COMPONENT_ID = 'product-history-comparison'

export default {
  name: 'ProductHistoryComparison',
  components: {
    RTabs,
    RTabItem,
    RTabItems,
    RErrorMessage,
    RAnchoredPopup,
    ProductCardSelector,
    ProductSelectionPopup,
  },
  data() {
    return {
      tabItems: [
        { label: 'Volume', icon: 'mdi-trending-up' },
        { label: 'Rating', icon: 'mdi-star' },
      ],
      selectedTab: 0,
      hoveredItem: null,
      highlightedItems: [],
      volumeSeries: [],
      ratingSeries: [],
      sort: 'name',
      sortDir: 'asc',
      selectedProductIds: [],
    }
  },
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    ...mapGetters('dashboardsData', [
      'getComponentSettings',
    ]),
    ...mapState('dashboardsData', [
      'allowComponentsToFetch',
      'productsHistory',
      'fetchingProductsHistory',
      'fetchProductsHistoryError',
    ]),
    chartOptions() {
      const ctx = this
      return {
        yAxis: {
          lineWidth: 1,
          title: {
            text: ctx.yAxisLabel,
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: true,
          },
        },
        series: this.series,
        tooltip: {
          enabled: true,
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            pointPadding: 0,
            groupPadding: 0,
            events: {
              click(event) {
                const product = ctx.series[event.point.series.index]
                ctx.onChangeHighlightedProduct({ value: product.id })
              },
            },
          },
        },
      }
    },
    yAxisLabel() {
      if (this.isRatingTabSelected()) {
        return 'Star'
      }

      if (this.isVolumeTabSelected()) {
        return 'No. of reviews'
      }

      throw Error('Can not resolve series for the selected tab')
    },
    series() {
      if (this.isRatingTabSelected()) {
        return this.$data.ratingSeries
      }

      if (this.isVolumeTabSelected()) {
        return this.$data.volumeSeries
      }

      throw Error('Can not resolve series for the selected tab')
    },
  },
  watch: {
    appliedFilters() {
      this.fetchData()
    },
  },
  mounted() {
    if (this.allowComponentsToFetch) { this.fetchData() }
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchDashboardProductsHistory',
      'saveDashboardComponentSettings',
    ]),
    async onChangeSelectedProducts({
      value, search, sort, sortDir,
    }) {
      const productIds = value ? value.map(({ _id }) => _id) : null
      await this.saveDashboardComponentSettings({
        componentId: COMPONENT_ID,
        productIds,
        sort,
        sortDir,
        search,
      })
      this.fetchData()
    },
    isHovered(item) {
      return this.hoveredItem === item
    },
    isHighlighted(item) {
      return this.highlightedItems.includes(item)
    },
    onChangeHoveredProduct({ value }) {
      if (this.highlightedItems.includes(value)) {
        return value
      }

      this.$data.hoveredItem = value
      return this.loadSeries()
    },
    onChangeHighlightedProduct({ value }) {
      if (this.isHighlighted(value)) {
        this.$data.highlightedItems = this.$data.highlightedItems.filter((item) => item !== value)
        this.$data.hoveredItem = null
        return this.loadSeries()
      }

      this.$data.highlightedItems = [...this.$data.highlightedItems, value]
      this.$data.hoveredItem = null
      return this.loadSeries()
    },
    fetchData() {
      const { sort = 'name', sortDir = 'asc', productIds = [] } = this.getComponentSettings(COMPONENT_ID)
      this.sort = sort
      this.sortDir = sortDir
      this.selectedProductIds = productIds
      this.fetchDashboardProductsHistory({ productIds })
        .then(() => {
          this.loadSeries()
          this.selectedProductIds = this.selectedProductIds.length > 0
            ? this.selectedProductIds
            : this.productsHistory.map(({ productId }) => productId)
        })
    },
    isVolumeTabSelected() {
      return this.$data.selectedTab === 0
    },
    isRatingTabSelected() {
      return this.$data.selectedTab === 1
    },
    createSeries({ dataKey }) {
      /* eslint-disable max-len */
      const isHighlightedData = (productId) => this.isHighlighted(productId) || this.isHovered(productId)

      return this.productsHistory
        .map((item, index) => ({
          name: item.name,
          id: item.productId,
          marker: { enabled: item.history.length === 1 },
          lineWidth: isHighlightedData(item.productId) ? 6 : 2,
          zIndex: isHighlightedData(item.productId) ? 2 : 1,
          color: getProductColorForNumber(index),
          data: item.history.map(({ [dataKey]: value, dateFrom }) => [dateStrToDate(dateFrom), value, item.productId]),
        }))
      /* eslint-enable max-len */
    },
    loadSeries() {
      this.$data.ratingSeries = this.createSeries({ dataKey: 'averageRating' })
      this.$data.volumeSeries = this.createSeries({ dataKey: 'volume' })
    },
  },
}
</script>

<style scoped>
.producthistorycomparison--tab-title {
  margin-right: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.07692307692rem;
  line-height: 20px;
  letter-spacing: -0.02px;
  color: var(--r-dark-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .rtabitems.content.v-item-group.v-tabs-items {
  min-height: auto !important;
}
</style>
