<template>
  <div class="dashboardfilterchips">
    <r-chip
      v-for="filter in renderedFilters"
      :key="`dashboardfilterchips-${filter.key}`"
      :label="filter.label"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove([filter.key])"
    />
    <r-chip
      v-if="entityGroupName"
      key="dashboardfilterchips-entitygroup"
      :label="`Reviews with entity group '${entityGroupName.toLowerCase()}'`"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove(['entityGroupId'])"
    />
    <r-chip
      v-if="sentimentRangeKey"
      key="dashboardfilterchips-sentiment"
      :label="sentimentFilterLabel"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove(['sentiment'])"
    />
    <r-chip
      v-if="entitySentimentRangeKey"
      key="dashboardfilterchips-entity-sentiment"
      :label="entitySentimentFilterLabel"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove(['entitySentiment'])"
    />
    <r-chip
      v-if="dateRange"
      key="dashboardfilterchips-date"
      :label="dateRangeLabel"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove(['dateRange'])"
    />
    <r-chip
      v-if="entityMapIsApplied"
      key="dashboardfilterchips-map"
      :label="entityMapLabel"
      :disabled="fetchingDashboardsData"
      removable
      class="dashboardfilterchips--chip ma-1"
      @click="onClickRemove(['entityMapId'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import RChip from '@/components/library/atoms/RChip'

const getCounterLabel = (value, singular, pleural) => {
  if (value.length === 1) {
    return `1 ${singular} only`
  }
  return `${value.length} ${pleural}`
}

const getCountriesLabel = (value) => {
  if (value.length === 0) {
    return ''
  }

  if (value.length === 1) {
    return `Country: ${value[0]}`
  }

  return `Country: ${value.join(', ')} or ${value[value.length - 1]}`
}

const getRatingLabel = (value) => {
  if (value.length === 1) {
    return `${value} Star only`
  }
  const head = [...value]
  const tail = head.pop()
  return `${head.join(', ')} or ${tail} Stars`
}

const SENTIMENT_TO_READABLE = {
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral',
  NEGATIVE: 'Negative',
}

const FILTER_TO_LABEL = {
  urlIds: (value) => getCounterLabel(value, 'Url', 'Urls'),
  ratings: (value) => getRatingLabel(value),
  countries: (value) => getCountriesLabel(value),
  hostnames: (value) => getCounterLabel(value, 'Domain', 'Domains'),
  search: () => 'Search applied',
  entity: (value) => `Reviews with '${value}' entity`,
}

const filterKeyMapper = (appliedFilters) => (key) => {
  const value = appliedFilters[key]
  return {
    key,
    label: FILTER_TO_LABEL[key](value),
  }
}

export default {
  name: 'DashboardFilterChips',
  components: {
    RChip,
  },
  computed: {
    ...mapGetters('entityMaps', [
      'entityMapIsApplied',
      'appliedEntityMap',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
      'sentimentRangeKey',
      'entitySentimentRangeKey',
      'dateRange',
      'entityGroupName',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
    ]),
    renderedFilters() {
      const renderableKeys = Object.keys(FILTER_TO_LABEL)

      return Object.keys(this.appliedFilters)
        .filter((key) => renderableKeys.includes(key))
        .map(filterKeyMapper(this.appliedFilters))
    },
    sentimentFilterLabel() {
      return `${SENTIMENT_TO_READABLE[this.sentimentRangeKey]} sentiment only`
    },
    entitySentimentFilterLabel() {
      return `${SENTIMENT_TO_READABLE[this.entitySentimentRangeKey]} entity sentiment only`
    },
    dateRangeLabel() {
      const [minDate, maxDate] = this.dateRange
      return `Date from ${minDate} to ${maxDate}`
    },
    entityMapLabel() {
      return `Entity map: '${this.appliedEntityMap.name}'`
    },
  },
  methods: {
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    ...mapActions('entityMaps', [
      'clearSelectedEntityMap',
    ]),
    onClickRemove(keys) {
      const filters = { ...this.appliedFilters }
      keys.forEach((key) => {
        filters[key] = null
      })
      this.setDashboardFilters(filters)
      if (keys.includes('entityMapId')) {
        this.clearSelectedEntityMap()
      }
    },
  },
}
</script>
