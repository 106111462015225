var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rratingfilterselector"
  }, [_c('v-row', _vm._l(_vm.ratingItems, function (rating, index) {
    return _c('v-col', {
      key: `rating-${rating}`,
      staticClass: "pa-0 ma-0 pl-3",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      staticClass: "ma-0 pa-0 checkbox-rating",
      attrs: {
        "dense": ""
      },
      on: {
        "change": _vm.onChangeSelectedRatings
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('r-rating', {
            attrs: {
              "value": rating
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.selectedRatings[index],
        callback: function ($$v) {
          _vm.$set(_vm.selectedRatings, index, $$v);
        },
        expression: "selectedRatings[index]"
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }