var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "singlebranddashboard"
  }, [_c('div', {
    staticClass: "pa-0"
  }, [_c('div', {
    staticClass: "px-14"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "py-6"
  }, [_c('dashboard-header', {
    staticClass: "singlebranddashboard--header",
    attrs: {
      "pinned": _vm.pinned,
      "name": _vm.name,
      "show-reviews": _vm.showReviews
    },
    on: {
      "show:reviews": _vm.onShowReviews
    }
  })], 1), _c('v-row', {
    staticClass: "singlebranddashboard--tabs ma-0 mt-2"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-grouped-tabs', {
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "items": _vm.SINGLE_BRAND_GROUPED_TABS
    },
    on: {
      "change": _vm.onChangeGroupTab,
      "change:sub-tab": _vm.onChangeSubTab
    }
  })], 1)], 1)], 1), !_vm.fetchingDashboardsData ? _c('v-row', {
    staticClass: "singlebranddashboard--filter-row mx-0 my-2"
  }, [_c('v-col', {
    staticClass: "pa-0 mb-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-date-selector', {
    staticClass: "d-flex",
    attrs: {
      "start-date": _vm.startDate,
      "end-date": _vm.endDate
    },
    on: {
      "change": _vm.onChangeDateRange
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-filter-chips', {
    staticClass: "d-flex flex-wrap flex-row-reverse"
  })], 1)], 1) : _c('v-row', {
    staticClass: "singlebranddashboard--filter-row mx-0"
  }, [_c('v-skeleton-loader', {
    staticClass: "elevation-0 filterrow--loader",
    attrs: {
      "type": "list-item",
      "height": "35",
      "width": "100%"
    }
  })], 1)], 1), _c('v-divider')], 1), _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    ref: "dashboardcontent",
    staticClass: "dashboardcontent px-14 pt-6",
    attrs: {
      "cols": _vm.showReviews ? 9 : 12
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_vm.selectedGroupTab === 0 && _vm.selectedSubTab === 0 ? _c('v-col', {
    staticClass: "singlebranddashboard--tab--summary"
  }, [_c('v-row', {
    staticClass: "singlebranddashboard--summary ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "singlebranddashboard--summary--box pa-6"
  }, [_c('r-big-number', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "title": _vm.$t('dashboards.singleBCT.components.totalNumReviewsTitle'),
      "value": _vm.numReviews,
      "previous-value": 0
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-time-line-chart', {
    attrs: {
      "title": _vm.$t('dashboards.singleBCT.components.numReviewsOverTime'),
      "spline": "",
      "duration-gap": _vm.timeLineChartDurationGap,
      "data": [{
        chartType: 'column',
        seriesData: _vm.timeLineChartSeriesData
      }],
      "height": 120
    },
    on: {
      "click:point": _vm.onClickTimeLineChartPoint
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-rating-time-line-chart', {
    attrs: {
      "title": _vm.$t('dashboards.singleBCT.components.averageStarRatingOverTime'),
      "loading": _vm.fetchingDashboardsData,
      "max-rate-value": 5,
      "data": _vm.netRatingHistorySeriesData
    },
    on: {
      "click:rating": _vm.onClickRating
    }
  })], 1)]), _c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "singlebranddashboard--summary--box pa-6"
  }, [_c('r-star-rating-distribution-chart', {
    attrs: {
      "title": _vm.$t('dashboards.singleBCT.components.starRatingDistribution'),
      "loading": _vm.fetchingDashboardsData,
      "data": _vm.starRatingDistribution
    },
    on: {
      "click:rating": _vm.onClickRating
    }
  }), _c('v-divider', {
    staticClass: "my-12"
  }), _c('r-net-sentiment-half-donut', {
    attrs: {
      "title": _vm.$t('dashboards.singleBCT.components.netSentimentDistribution'),
      "loading": _vm.fetchingDashboardsData,
      "net-sentiment": _vm.netSentiment,
      "num-negative": _vm.sentimentBreakdown.negative,
      "num-neutral": _vm.sentimentBreakdown.neutral,
      "num-positive": _vm.sentimentBreakdown.positive
    },
    on: {
      "click:sentiment": _vm.onClickSentiment
    }
  })], 1)]), _c('v-col', {
    staticClass: "pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "singlebranddashboard--summary--box pa-6"
  }, [_c('r-top-urls', {
    attrs: {
      "highest-rated": _vm.highestRatedUrls,
      "most-reviewed": _vm.mostReviewedUrls
    }
  }), _c('v-divider', {
    staticClass: "my-10"
  }), _c('r-trends', {
    staticClass: "singlebranddashboard--rtrends",
    attrs: {
      "days": _vm.trendsSelectedDays,
      "loading": _vm.fetchingDashboardsData || _vm.fetchingTrends,
      "total-positive": _vm.trends.totalPositive,
      "total-negative": _vm.trends.totalNegative,
      "positive-percent-increase": _vm.trends.positivePercentIncrease,
      "negative-percent-increase": _vm.trends.negativePercentIncrease,
      "positive-data": _vm.trends.positiveData,
      "negative-data": _vm.trends.negativeData,
      "positive-entities": _vm.trends.positiveEntities,
      "negative-entities": _vm.trends.negativeEntities
    },
    on: {
      "change:daterange": _vm.handleTrendsDateChange,
      "click:entity-sentiment": _vm.onClickEntitySentiment,
      "click:entity": _vm.onClickEntity
    }
  })], 1)])], 1), _c('v-row', {
    staticClass: "singlebranddashboard--sites mx-0"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-sites-analyzed', {
    attrs: {
      "data": _vm.sites,
      "loading": _vm.fetchingDashboardsData
    },
    on: {
      "click:hostname": _vm.onClickHostname
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "singlebranddashboard--comparison-chart mx-0"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-comparison-chart', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "data": _vm.comparisonChartData.data,
      "breakdown-keys": _vm.comparisonChartData.breakdownKeys
    },
    on: {
      "change:series-one": _vm.onChangeComparisonSeriesOne,
      "change:series-two": _vm.onChangeComparisonSeriesTwo,
      "click:series": _vm.onClickComparisonChartSeries
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.selectedGroupTab === 0 && _vm.selectedSubTab === 1 ? _c('v-col', [_c('v-row', {
    staticClass: "singlebranddashboard--tab--trending ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-component-header', {
    staticClass: "rcomponentheader--bordered"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.components.trending')) + " ")]), _c('r-rating-time-line-comparison', {
    attrs: {
      "data": _vm.getUrlsHistory
    },
    on: {
      "change:sort": _vm.onChangeOfRatingComparisonSort,
      "change:num": _vm.onChangeOfRatingComparisonDays
    }
  }), _c('trending-data', {
    attrs: {
      "trending-data": _vm.comparisonTrendingData,
      "loading": _vm.fetchingDashboardsData || _vm.fetchingDashboardsTrendingData
    },
    on: {
      "change:period-date-range": _vm.onChangeTrendingPeriodDate,
      "click:sentiment": _vm.onClickSentiment,
      "click:entity-name": _vm.onClickEntityName,
      "click:word": _vm.onClickWord,
      "click:rating": _vm.onClickRating
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.selectedGroupTab === 0 && _vm.selectedSubTab === 2 ? _c('v-col', [_c('v-row', {
    staticClass: "singlebranddashboard--tab--entity ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-component-header', {
    staticClass: "rcomponentheader--bordered",
    scopedSlots: _vm._u([{
      key: "end",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_vm.entityMapIsApplied ? _c('r-button', {
          staticClass: "wordcloudheaderbutton mr-2",
          attrs: {
            "label": "Add to entity map",
            "outlined": "",
            "disabled": _vm.selectedWords.length === 0
          },
          on: {
            "click": _vm.onClickAddToEntityMap
          }
        }) : _vm._e(), _vm.entityMapIsApplied ? _c('r-button', {
          staticClass: "wordcloudheaderbutton",
          attrs: {
            "label": "Irrelevant",
            "icon": "delete",
            "outlined": "",
            "disabled": _vm.selectedWords.length === 0
          },
          on: {
            "click": _vm.onClickIgnore
          }
        }) : _vm._e(), _c('r-anchored-popup', {
          staticClass: "wordcloudheaderbutton entitysettingspopup",
          attrs: {
            "icon": "mdi-cog",
            "label": "",
            "fab-icon": "",
            "small-button": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var onClose = _ref.onClose;
              return [_c('entity-settings', {
                attrs: {
                  "applied-color-by-filter": _vm.appliedColorByFilter,
                  "applied-highlight-filter": _vm.appliedHighlightFilter,
                  "applied-separate-by-filter": _vm.appliedSeparateByFilter
                },
                on: {
                  "apply": _vm.onApplyEntitySettingsFilter,
                  "close": onClose
                }
              })];
            }
          }], null, false, 2351141634)
        })], 1)];
      },
      proxy: true
    }], null, false, 7621578)
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.components.wordCloud')) + " ")]), _vm.showSingleCloudWord ? _c('r-word-cloud', {
    attrs: {
      "entities": _vm.wordCloudData,
      "color": _vm.appliedColorByFilter,
      "time-buckets": _vm.timeBuckets,
      "disable-entity-map-controls": !_vm.entityMapIsApplied,
      "loading-data": _vm.fetchingWordCloudData
    },
    on: {
      "change:selected-words": _vm.onChangeSelectedWords,
      "click:edit-entity-map-group": _vm.onClickEditEntityMapGroup,
      "click:word": _vm.onClickWord
    }
  }) : _c('r-word-cloud-grouped', {
    attrs: {
      "applied-color-by-filter": _vm.appliedColorByFilter,
      "applied-highlight-filter": _vm.appliedHighlightFilter,
      "applied-separate-by-filter": _vm.appliedSeparateByFilter,
      "entity-map-is-applied": _vm.entityMapIsApplied,
      "is-getting-entities": _vm.fetchingWordCloudData,
      "time-buckets": _vm.timeBuckets,
      "word-cloud-grouped": _vm.wordCloudGroupedData
    },
    on: {
      "change:selected-words": _vm.onChangeSelectedWords,
      "click:edit-entity-map-group": _vm.onClickEditEntityMapGroup,
      "click:word": _vm.onClickWord
    }
  }), _vm.appliedColorByFilter ? _c('KeyColor', {
    attrs: {
      "applied-colors": _vm.appliedColors,
      "reversed-keys": _vm.appliedColorByFilter === 'rating'
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-component-header', {
    staticClass: "rcomponentheader--bordered"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.components.entityProgress')) + " ")]), _c('entity-progress', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "width": _vm.entityProgressBarChartWidth
    },
    on: {
      "click:entity": _vm.onClickEntity
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-component-header', {
    staticClass: "rcomponentheader--bordered"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.components.topicWheel')) + " ")]), _c('r-topic-wheel', {
    attrs: {
      "title": "",
      "hierarchical": true,
      "skeleton-size": 370,
      "data": _vm.topicWheelData,
      "loading": _vm.fetchingDashboardsEntitiesWithGroupData
    },
    on: {
      "click:entity-name": _vm.onClickEntityName
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.selectedGroupTab === 0 && _vm.selectedSubTab === 3 ? _c('v-col', [_c('v-row', {
    staticClass: "singlebranddashboard--tab--location ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-component-header', {
    staticClass: "rcomponentheader--bordered"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.components.location')) + " ")])], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('r-map', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "data": _vm.locationsMap
    },
    on: {
      "click:country": _vm.onClickMapCountry
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('r-sentiment-bar-chart', {
    attrs: {
      "loading": _vm.fetchingDashboardsData,
      "data": _vm.locationsChart,
      "width": _vm.barChartWidth
    },
    on: {
      "click:entity": _vm.onClickLocationEntity
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showReviews,
      expression: "showReviews"
    }],
    ref: "reviewscardsection",
    staticClass: "reviewscardsection pa-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('r-review-card-list', {
    key: `review-card-list-${_vm.showReviews}`,
    attrs: {
      "reviews": _vm.reviews,
      "total-reviews": _vm.numReviews,
      "loading": _vm.fetchingReviews,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "total-pages": _vm.reviewsTotalPage,
      "search": _vm.appliedFilters.search,
      "applied-entities": _vm.appliedEntityFilters
    },
    on: {
      "change:page": _vm.onReviewsPageChange,
      "change:sort": _vm.onReviewsSortChange,
      "close": function () {
        return _vm.onShowReviews({
          value: false
        });
      }
    }
  })], 1)], 1), _vm.showEditEntityMap ? _c('edit-entity-group-modal', {
    attrs: {
      "entities": _vm.$data.selectedWords.map(function (_ref2) {
        var label = _ref2.label;
        return label;
      }),
      "entity-group-id": _vm.editEntityMapGroupId
    },
    on: {
      "close": function ($event) {
        _vm.showEditEntityMap = false;
      },
      "submit": _vm.onClickSubmitEntityGroupModal
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }