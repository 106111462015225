var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "multiproductdashboard"
  }, [_c('div', {
    staticClass: "pa-0"
  }, [_c('div', {
    staticClass: "px-14"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "py-6"
  }, [_c('dashboard-header', {
    staticClass: "multiproductdashboard--header",
    attrs: {
      "pinned": _vm.pinned,
      "name": _vm.name,
      "show-reviews": _vm.showReviews
    },
    on: {
      "show:reviews": _vm.onShowReviews
    }
  })], 1), _c('v-row', {
    staticClass: "multiproductdashboard--tabs ma-0 mt-2"
  }, [_c('v-col', {
    staticClass: "pa-0"
  }, [_c('r-grouped-tabs', {
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "items": _vm.groupedTabs,
      "show-delete-group": true
    },
    on: {
      "change": _vm.onChangeGroupTab,
      "change:sub-tab": _vm.onChangeSubTab,
      "on:delete-group": _vm.onDeleteGroup
    },
    scopedSlots: _vm._u([{
      key: "separateTab",
      fn: function () {
        return [_c('r-anchored-popup', {
          attrs: {
            "anchor": "top-left",
            "disabled": _vm.spdLimitReached,
            "label": "Product deep dive + "
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref) {
              var onClose = _ref.onClose;
              return [_c('add-single-product-dashboard', {
                attrs: {
                  "on-close": onClose,
                  "preselected-products": _vm.selectedProducts
                },
                on: {
                  "on:add-dashboards": _vm.onAddDashboards
                }
              })];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), !_vm.fetchingDashboardsData ? _c('v-row', {
    staticClass: "multiproductdashboard--filter-row mx-0"
  }, [_c('v-col', {
    staticClass: "pa-0 mb-3",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-date-selector', {
    staticClass: "d-flex",
    attrs: {
      "start-date": _vm.startDate,
      "end-date": _vm.endDate
    },
    on: {
      "change": _vm.onChangeDateRange
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('dashboard-filter-chips', {
    staticClass: "d-flex flex-wrap flex-row-reverse"
  })], 1)], 1) : _c('v-row', {
    staticClass: "multiproductdashboard--filter-row mx-0"
  }, [_c('v-skeleton-loader', {
    staticClass: "elevation-0 filterrow--loader",
    attrs: {
      "type": "list-item",
      "height": "30",
      "width": "100%"
    }
  })], 1)], 1), _c('v-divider')], 1), _vm.showSPD ? _c('single-product-dashboard-content', {
    ref: "spdcontent",
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "show-reviews": _vm.showReviews
    },
    on: {
      "on:show-reviews": _vm.onShowReviews
    }
  }) : _c('multi-product-dashboard-content', {
    ref: "mpdcontent",
    attrs: {
      "selected-group-tab": _vm.selectedGroupTab,
      "selected-sub-tab": _vm.selectedSubTab,
      "show-reviews": _vm.showReviews
    },
    on: {
      "on:show-reviews": _vm.onShowReviews
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }