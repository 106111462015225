<template>
  <div class="editentitygroup pa-2 pb-8 pl-8">
    <div
      v-if="editMode"
      class="d-flex justify-space-between"
    >
      <v-text-field
        ref="groupName"
        v-model="groupName"
        :rules="[rules.required, (v) => rules.uniqueGroupName(v, groups)]"
        dense
        outlined
        placeholder="Entity group name"
        class="groupname--input"
        @blur="onSetGroupName"
        @keyup.enter="onSetGroupName"
      />
      <v-btn
        icon
        small
        class="delete--button"
        @click="onClickDeleteEntityGroup"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </div>
    <div
      v-else
      class="d-flex justify-space-between mb-2 pr-1"
    >
      <p class="ma-0 mt-1">
        {{ groupName }}
      </p>
      <div>
        <v-btn
          small
          icon
          class="edit--button"
          @click="editMode = true"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          small
          icon
          class="delete--button"
          @click="onClickDeleteEntityGroup"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </div>
    </div>

    <r-text-chips
      :disabled="localEntities.length >= ENTITYMAPS_LIMITS.ENTITIES_PER_GROUP"
      class="px-1"
      :chips="localEntities"
      @add-chip="onAddEntity"
      @remove-chip="onRemoveEntity"
    />

    <r-error-message
      :key="`editentitygroup--errormessage-${validationCount}`"
      :errors="validationErrors"
    />
  </div>
</template>

<script>
import RTextChips from '@/components/library/molecules/RTextChips'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { ENTITYMAPS_LIMITS } from '@/utils/constants'

export default {
  name: 'EditEntityGroup',
  components: {
    RTextChips,
    RErrorMessage,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    entities: {
      type: Array,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localEntities: [],
    editMode: false,
    groupName: '',
    rules: {
      required: (v) => (v?.length > 0) || 'You must supply a group name.',
      uniqueGroupName: (v, groups) => (groups.reduce((
        count,
        currentValue,
      ) => {
        if (currentValue.name.toString().toLowerCase() === v.toLowerCase()) {
          // eslint-disable-next-line no-param-reassign
          count += 1
          return count
        }
        return count
      },
      0)) < 2 || 'Group Name already taken',
    },
    validationErrors: [],
    validationCount: 0,
    ENTITYMAPS_LIMITS,
  }),
  watch: {
    groupName(value) {
      this.$emit('change:name', { value })
    },
    entities: {
      handler() {
        this.$data.localEntities = [...this.$props.entities]
      },
      immediate: true,
    },
    editing: {
      handler() {
        this.editMode = this.$props.editing
      },
      immediate: true,
    },
    name: {
      handler() {
        this.groupName = this.$props.name
      },
      immediate: true,
    },
  },
  methods: {
    onAddEntity({ value }) {
      this.$data.localEntities = [...new Set(
        this.$data.localEntities.concat(value),
      )]
      this.$emit('change:entities', { value: this.$data.localEntities })
    },
    onRemoveEntity({ value }) {
      this.$data.localEntities = this.$data.localEntities.filter((v) => v !== value)
      this.$emit('change:entities', { value: this.$data.localEntities })
    },
    onSetGroupName() {
      if (this.$refs.groupName.validate()) {
        this.$data.editMode = false
      }
    },
    validate() {
      const { localEntities, editMode } = this.$data
      this.$data.validationErrors = []
      this.$data.validationCount += 1
      let ok = true
      if (editMode && !this.$refs.groupName.validate()) {
        this.$data.validationErrors.push(this.$data.rules.required(false))
        ok = false
      }
      if (!localEntities.length) {
        this.$data.validationErrors.push('You must supply at least one entity.')
        ok = false
      }
      return ok
    },
    onClickDeleteEntityGroup() {
      this.$emit('delete')
    },
  },
}
</script>

<style scoped>
.editentitygroup {
  background: var(--r-light-grey);
}
/deep/ .v-input__slot,
/deep/ .rtextchips {
  background: #fff !important;
}
</style>
