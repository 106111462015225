<template>
  <div class="productstable">
    <v-row>
      <v-col>
        <r-component-header class="productstable--header-border">
          <template #end>
            <r-anchored-popup
              v-slot="{ onClose }"
              ref="productSelectionPopup"
              label="Select Products"
            >
              <product-selection-popup
                :preselected-sort="sort"
                :preselected-sort-dir="sortDir"
                :preselected-product-ids="selectedProducts"
                @change:selected-products="onChangeSelectedProducts"
                @onClose="onClose"
              />
            </r-anchored-popup>
          </template>
          <span class="px-1">
            Dashboard Products
          </span>
        </r-component-header>
      </v-col>
    </v-row>

    <v-row v-if="noData">
      <v-col>
        <p>No products are selected yet! Select products for products comparison</p>
      </v-col>
    </v-row>
    <r-table
      v-else
      class="productstable--table"
      :is-selectable="false"
      :columns="tableColumns"
      :rows="products"
      :total-rows="totalProducts"
      :page="page"
      :page-size="pageSize"
      :loading="fetchingProductsOfTable"
      @change:page="onClickPage"
    >
      <template v-slot:item.name="{ item }">
        <span
          class="productstable--name text-truncate"
          :title="item.name"
        >
          {{ item.name }}
        </span>
      </template>
      <template v-slot:item.averageRating="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ parseFloat(item.averageRating.toFixed(1)) }}</span>
          <v-rating
            v-model="item.averageRating"
            length="1"
            color="yellow darken-2"
            background-color="yellow darken-2"
          />
        </div>
      </template>
      <template v-slot:item.numReviews="{ item }">
        <div class="d-flex justify-start align-center">
          <span>{{ item.numReviews }}</span>
        </div>
      </template>
    </r-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import RComponentHeader from '@/components/library/atoms/RComponentHeader.vue'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import ProductSelectionPopup from '@/components/app/dashboards/common/ProductSelectionPopup.vue'
import RTable from '@/components/library/organisms/RTable.vue'
import debounce from '@/utils/debounce'

const COMPONENT_ID = 'products-table'

export default {
  name: 'ProductsTable',
  components: {
    RComponentHeader,
    RAnchoredPopup,
    ProductSelectionPopup,
    RTable,
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Product Name',
        hideable: false,
        sortable: false,
        width: 250,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: false,
        sortable: false,
        width: 50,
      },
      {
        key: 'numUrls',
        label: 'URLs',
        hideable: false,
        sortable: false,
        width: 50,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: false,
        sortable: false,
        width: 100,
      },
    ],

    page: 1,
    pageSize: 10,
    sort: 'name',
    sortDir: 'asc',
    selectedProducts: [],
    debouncedFetchData: null,
  }),
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    ...mapGetters('dashboardsData', [
      'getComponentSettings',
      'dashboardProductIds',
    ]),
    ...mapState('dashboardsData', {
      allowComponentsToFetch: 'allowComponentsToFetch',
      fetchingProductsOfTable: 'fetchingProductsOfTable',
      products: 'productsOfTable',
      totalProducts: 'totalProductsOfTable',
    }),
    noData() {
      return this.products.length === 0 && !this.fetchingProductsOfTable
    },
  },
  watch: {
    appliedFilters() {
      this.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    if (this.allowComponentsToFetch) { this.fetchData() }
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchProductsOfTable',
      'saveDashboardComponentSettings',
    ]),
    async fetchData() {
      const { page, pageSize } = this.$data
      const { sort = 'name', sortDir = 'asc', productIds = [] } = this.getComponentSettings(COMPONENT_ID)
      this.sort = sort
      this.sortDir = sortDir
      this.selectedProducts = productIds?.length ? productIds : this.dashboardProductIds
      await this.fetchProductsOfTable({ page, pageSize, componentId: COMPONENT_ID })
    },
    async onChangeSelectedProducts({
      value, search, sort, sortDir,
    }) {
      const productIds = value ? value.map(({ _id }) => _id) : null
      await this.saveDashboardComponentSettings({
        componentId: COMPONENT_ID,
        productIds,
        sort,
        sortDir,
        search,
      })
      this.page = 1
      this.fetchData()
    },
    onClickPage({ value: page }) {
      this.page = page
      this.fetchData()
    },
  },
}
</script>

<style scoped>
.productstable /deep/ .rtable--row {
  justify-content: space-between;
}
.productstable /deep/ .rtable--cell:first-child {
  padding-left: 16px;
}
.productstable--header-border {
  border-bottom: 1px solid var(--r-border-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.productstable--name {
  width: 450px;
  display: block;
}

@media screen and (max-width: 1024px) {
  .productstable--name {
    width: 238px;
  }
}
.productstable--table,
/deep/ .productstable--table table,
/deep/ .productstable--table table tbody {
  height: fit-content!important;
}
</style>
