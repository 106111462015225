<template>
  <div class="productsentimentcomparison">
    <v-row>
      <v-col>
        <r-component-header class="productsentimentcomparison--header-border">
          <template #end>
            <r-anchored-popup
              v-slot="{ onClose }"
              ref="productSelectionPopup"
              label="Select Products"
            >
              <product-selection-popup
                :preselected-sort="sort"
                :preselected-sort-dir="sortDir"
                :preselected-product-ids="selectedProducts"
                @change:selected-products="onChangeSelectedProduct"
                @onClose="onClose"
              />
            </r-anchored-popup>
          </template>
          <span class="px-1">
            Sentiment Comparison
          </span>
        </r-component-header>
      </v-col>
    </v-row>

    <v-row v-if="fetchingProductsSentiment">
      <v-col
        v-for="n in 10"
        :key="n"
        cols="12"
        class="productsentimentcomparison--loader pa-0"
      >
        <v-skeleton-loader
          type="image"
          width="100%"
          height="25"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="noData">
      <v-col>
        <p>No products are selected yet! Select products for sentiment comparison</p>
      </v-col>
    </v-row>

    <v-row
      v-for="productData in products"
      v-else
      :key="productData.productId"
      class="mx-0 mt-4 mb-1"
    >
      <r-stacked-bar-chart
        :properties="['positive', 'neutral', 'negative']"
        :data="productData"
      />
    </v-row>
    <v-row
      class="productsentimentcomparison-legends ma-0 mb-1"
      justify="space-between"
    >
      <v-col>
        <p
          v-for="sentiment in ['Positive', 'Neutral', 'Negative']"
          :key="`${sentiment}`"
          class="d-inline mx-2"
        >
          <v-icon
            :style="legendStyle(sentiment)"
            small
          >
            fiber_manual_record
          </v-icon>
          {{ sentiment }}
        </p>
      </v-col>
      <v-col>
        <r-pagination
          :page="page"
          :total-pages="Math.ceil(totalProducts / pageSize)"
          justify="end"
          @click:page="onClickPage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import chartKeyToColor from '@/utils/chartKeyToColor'
import RStackedBarChart from '@/components/library/atoms/RStackedBarChart'
import RComponentHeader from '@/components/library/atoms/RComponentHeader.vue'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup.vue'
import ProductSelectionPopup from '@/components/app/dashboards/common/ProductSelectionPopup.vue'
import RPagination from '@/components/library/molecules/RPagination'
import debounce from '@/utils/debounce'

const COMPONENT_ID = 'product-sentiment-comparison'

export default {
  name: 'ProductSentimentComparison',
  components: {
    RComponentHeader,
    RStackedBarChart,
    RAnchoredPopup,
    ProductSelectionPopup,
    RPagination,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      sort: 'name',
      sortDir: 'asc',
      selectedProducts: [],
      debouncedFetchData: null,
    }
  },
  computed: {
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
    ]),
    ...mapGetters('dashboardsData', [
      'getComponentSettings',
      'dashboardProductIds',
    ]),
    ...mapState('dashboardsData', {
      allowComponentsToFetch: 'allowComponentsToFetch',
      fetchingProductsSentiment: 'fetchingProductsSentiment',
      products: 'productsSentiment',
      totalProducts: 'totalProductsSentiment',
    }),
    noData() {
      return this.products.length === 0
    },
  },
  watch: {
    appliedFilters() {
      this.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    if (this.allowComponentsToFetch) { this.fetchData() }
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchProductsSentiment',
      'saveDashboardComponentSettings',
    ]),
    async fetchData() {
      const { page, pageSize } = this.$data
      const { sort = 'name', sortDir = 'asc', productIds = [] } = this.getComponentSettings(COMPONENT_ID)
      this.sort = sort
      this.sortDir = sortDir
      this.selectedProducts = productIds?.length ? productIds : this.dashboardProductIds
      await this.fetchProductsSentiment({ page, pageSize })
    },
    legendStyle(sentiment) {
      return {
        color: chartKeyToColor(sentiment),
        'font-size': '11.5px',
        'font-weight': 400,
        'line-height': '20px',
        padding: '0 5px',
      }
    },
    async onChangeSelectedProduct({
      value, search, sort, sortDir,
    }) {
      const productIds = value ? value.map(({ _id }) => _id) : null
      await this.saveDashboardComponentSettings({
        componentId: COMPONENT_ID,
        productIds,
        sort,
        sortDir,
        search,
      })
      this.page = 1
      this.fetchData()
    },
    onClickPage({ value: page }) {
      this.page = page
      this.fetchData()
    },
  },
}
</script>

<style scoped>

.productsentimentcomparison--header-border {
  border-bottom: 1px solid var(--r-border-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.productsentimentcomparison--loader {
  height: 25px;
  padding: 0;
  margin: 16px 0;
}
</style>
