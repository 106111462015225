<template>
  <div
    class="productcard mx-1 pa-3 d-flex align-center justify-start"
    :class="{ active: isSelected, hovered: isHovered }"
    :title="name"
    @click="() => onClickProductCard({ value: id })"
    @mouseenter="() => onHoverIn({ value: id })"
    @mouseleave="() => onHoverLeave({ value: id })"
  >
    <v-icon
      class="productcard--icon"
      :color="color"
    >
      circle
    </v-icon>
    <div class="productcard--name text-truncate ml-2">
      {{ name }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'RProductCard',
  props: {
    isSelected: {
      type: Boolean,
      required: false,
    },
    isHovered: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      require: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    onHoverIn({ value }) {
      this.$emit('mouseenter', { value })
    },
    onHoverLeave({ value }) {
      this.$emit('mouseleave', { value })
    },
    onClickProductCard({ value }) {
      this.$emit('click', { value })
    },
  },
}
</script>

<style scoped>
.productcard {
  cursor: pointer;
  background-color: var(--light-grey-color);
}
.productcard.active {
  border: 3px solid var(--attention-color);
}
.productcard.hovered {
  border: 1px solid var(--attention-color);
}
.productcard--name {
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 700;
  color: var(--r-carbon-color);
}
</style>
