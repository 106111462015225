<template>
  <v-row
    class="ma-0"
  >
    <v-col
      ref="dashboardcontent"
      :cols="showReviews ? 9 : 12"
      class="dashboardcontent px-14 pt-6"
    >
      <v-row class="ma-0">
        <!-- Summary Tab -->
        <v-col
          v-if="selectedGroupTab === 0 && selectedSubTab === 0"
          class="multiproductdashboard--tab--summary"
        >
          <v-row class="multiproductdashboard--summary ma-0">
            <v-col
              class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
              cols="4"
            >
              <div class="multiproductdashboard--summary--box pa-6">
                <r-big-number
                  :loading="fetchingDashboardsData"
                  :title="$t('dashboards.components.totalNumReviewsTitle')"
                  :value="numReviews"
                  :previous-value="0"
                />
                <v-divider class="my-12" />
                <r-time-line-chart
                  :title="$t('dashboards.components.numReviewsOverTime')"
                  spline
                  :duration-gap="timeLineChartDurationGap"
                  :data="[{
                    chartType: 'column',
                    seriesData: timeLineChartSeriesData,
                  }]"
                  :height="120"
                  @click:point="onClickTimeLineChartPoint"
                />
                <v-divider class="my-12" />
                <r-rating-time-line-chart
                  :title="$t('dashboards.components.averageStarRatingOverTime')"
                  :loading="fetchingDashboardsData"
                  :max-rate-value="5"
                  :data="netRatingHistorySeriesData"
                  @click:rating="onClickRating"
                />
              </div>
            </v-col>
            <v-col
              class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
              cols="4"
            >
              <div class="multiproductdashboard--summary--box pa-6">
                <r-star-rating-distribution-chart
                  :title="$t('dashboards.components.starRatingDistribution')"
                  :loading="fetchingDashboardsData"
                  :data="starRatingDistribution"
                  @click:rating="onClickRating"
                />
                <v-divider class="my-12" />
                <r-net-sentiment-half-donut
                  :title="$t('dashboards.components.netSentimentDistribution')"
                  :loading="fetchingDashboardsData"
                  :net-sentiment="netSentiment"
                  :num-negative="sentimentBreakdown.negative"
                  :num-neutral="sentimentBreakdown.neutral"
                  :num-positive="sentimentBreakdown.positive"
                  @click:sentiment="onClickSentiment"
                />
              </div>
            </v-col>
            <v-col
              class="pa-0 px-2 col-md-4 col-sm-12 mb-md-3 mb-sm-3"
              cols="4"
            >
              <div class="multiproductdashboard--summary--box pa-6">
                <r-top-sentiment-review-cards
                  :loading="fetchingDashboardsData"
                  :search="search"
                  :applied-entities="appliedEntityFilters"
                  :positive-review="topPositiveReview"
                  :negative-review="topNegativeReview"
                  @click:see-all-reviews="() => onShowReviews({ value: true })"
                />
                <v-divider class="my-12" />
                <r-trends
                  :days="trendsSelectedDays"
                  :loading="fetchingDashboardsData || fetchingTrends"
                  :total-positive="trends.totalPositive"
                  :total-negative="trends.totalNegative"
                  :positive-percent-increase="trends.positivePercentIncrease"
                  :negative-percent-increase="trends.negativePercentIncrease"
                  :positive-data="trends.positiveData"
                  :negative-data="trends.negativeData"
                  :positive-entities="trends.positiveEntities"
                  :negative-entities="trends.negativeEntities"
                  @change:daterange="handleTrendsDateChange"
                  @click:entity-sentiment="onClickEntitySentiment"
                  @click:entity="onClickEntity"
                />
              </div>
            </v-col>
          </v-row>

          <v-row class="multiproductdashboard--products-table px-5 py-10">
            <v-col class="pa-0">
              <products-table />
            </v-col>
          </v-row>

          <v-row class="multiproductdashboard--sites px-5 py-10">
            <v-col class="pa-0">
              <r-sites-analyzed-table
                :data="sites"
                :loading="fetchingDashboardsData"
                @click:hostname="onClickHostname"
              />
            </v-col>
          </v-row>

          <v-row class="multiproductdashboard--product-sentiment-comparison px-5 py-10">
            <v-col class="pa-0">
              <product-sentiment-comparison />
            </v-col>
          </v-row>

          <v-row class="multiproductdashboard--product-history-comparison px-5 py-10">
            <v-col class="pa-0">
              <product-history-comparison />
            </v-col>
          </v-row>
        </v-col>

        <!-- Entity Tab -->
        <v-col
          v-if="selectedGroupTab === 0 && selectedSubTab === 1"
          class="multiproductdashboard---tab--entity ma-0"
        >
          <v-row class="multiproductdashboard--top-entities">
            <v-col class="pa-0">
              <product-entities-comparison />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <!-- Reviews Panel -->
    <v-col
      v-show="showReviews"
      cols="3"
      class="reviewscardsection pa-0"
    >
      <r-review-card-list
        :key="`review-card-list-${showReviews}`"
        :reviews="reviews"
        :total-reviews="numReviews"
        :loading="fetchingReviews"
        :page="page"
        :page-size="pageSize"
        :total-pages="reviewsTotalPage"
        :search="appliedFilters.search"
        :applied-entities="appliedEntityFilters"
        @change:page="onReviewsPageChange"
        @change:sort="onReviewsSortChange"
        @close="() => onShowReviews({ value: false })"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RStarRatingDistributionChart from '@/components/library/molecules/RStarRatingDistributionChart.vue'
import RBigNumber from '@/components/library/molecules/RBigNumber.vue'
import ProductHistoryComparison from '@/components/app/dashboards/ProductHistoryComparison.vue'
import RNetSentimentHalfDonut from '@/components/library/molecules/RNetSentimentHalfDonut.vue'
import RReviewCardList from '@/components/library/molecules/RReviewCardList.vue'
import ProductSentimentComparison from '@/components/app/dashboards/ProductSentimentComparison.vue'
import RTrends from '@/components/library/molecules/RTrends.vue'
import RTimeLineChart from '@/components/library/atoms/RTimeLineChart.vue'
import RRatingTimeLineChart from '@/components/library/atoms/RRatingTimeLineChart.vue'
import RSitesAnalyzedTable from '@/components/library/atoms/RSitesAnalyzedTable.vue'
import ProductEntitiesComparison from '@/components/app/dashboards/ProductEntitiesComparison.vue'
import RTopSentimentReviewCards from '@/components/library/molecules/RTopSentimentReviewCards.vue'
import { generateTimeBuckets } from '@/components/app/dashboards/utils'
import ProductsTable from '@/components/app/dashboards/ProductsTable'

const PADDING_X = 112
const STACKED_LABEL_TEXT_WIDTH = 100
const REVIEWS_LIST_WIDTH = 400
export default {
  name: 'MultiProductDashboardContent',
  components: {
    RTopSentimentReviewCards,
    ProductEntitiesComparison,
    RSitesAnalyzedTable,
    RRatingTimeLineChart,
    RTimeLineChart,
    RTrends,
    ProductSentimentComparison,
    RReviewCardList,
    RNetSentimentHalfDonut,
    ProductHistoryComparison,
    RBigNumber,
    RStarRatingDistributionChart,
    ProductsTable,
  },
  props: {
    selectedGroupTab: {
      type: Number,
      default: 0,
    },
    selectedSubTab: {
      type: Number,
      default: 0,
    },
    showReviews: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    page: 1,
    pageSize: 50,
    sort: 'date',
    sortDir: 'desc',
    trendsSelectedDays: 7,
    barChartWidth: 0,
    timeBuckets: [],
    timeBucketIntervals: [],
  }),
  computed: {
    ...mapGetters('dashboardsData', [
      'name',
      'numReviews',
      'pinned',
      'starRatingDistribution',
      'sentimentBreakdown',
      'netSentiment',
      'topPositiveReview',
      'topNegativeReview',
      'reviews',
      'sites',
      'dashboardId',
      'timeLineChartDurationGap',
      'timeLineChartSeriesData',
      'netRatingHistorySeriesData',
    ]),
    ...mapGetters('dashboardsFilters', [
      'appliedFilters',
      'appliedEntityFilters',
      'dateRange',
      'dateRangeAllIsSelected',
    ]),
    ...mapState('dashboardsData', [
      'fetchingDashboardsData',
      'fetchingReviews',
      'trends',
      'fetchingTrends',
      'urls',
    ]),
    ...mapState('dashboardsFilters', [
      'search',
    ]),
    reviewsTotalPage() {
      return Math.floor(this.numReviews / this.pageSize)
    },
  },
  watch: {
    async appliedFilters() {
      this.page = 1
      await this.fetchData()
      this.fetchReviews()
    },
    dateRange() {
      const { minDate, maxDate } = this.appliedFilters
      if (minDate && maxDate) {
        this.startDate = minDate
        this.endDate = maxDate
        // TODO: to be checked later
        // time buckets logic is for
        //  word cloud I guess for MPD
        // this logic should be deleted
        this.setTimeBuckets({ minDate, maxDate })
      }
    },
  },
  async mounted() {
    this.setBarchartWidth()
    window.addEventListener('resize', this.setDimensions)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDimensions)
  },
  methods: {
    ...mapActions('dashboardsData', [
      'fetchDashboardsData',
      'fetchDashboardsReviews',
      'fetchDashboardsTrends',
    ]),
    ...mapActions('dashboardsFilters', [
      'setDashboardFilters',
    ]),
    setTimeBuckets({ minDate, maxDate }) {
      const { timeBuckets, timeBucketIntervals } = generateTimeBuckets({
        minDate,
        maxDate,
        moment: this.$moment,
      })
      this.timeBuckets = timeBuckets
      this.timeBucketIntervals = timeBucketIntervals
    },
    setBarchartWidth() {
      this.barChartWidth = this.showReviews
        ? (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH - REVIEWS_LIST_WIDTH) / 2
        : (window.innerWidth - PADDING_X - STACKED_LABEL_TEXT_WIDTH) / 2
    },
    onShowReviews({ value }) {
      this.$emit('on:show-reviews', { value })
      this.setDimensions()
    },
    getPaginationPayload() {
      return {
        page: this.page,
        pageSize: this.pageSize,
      }
    },
    getSortingPayload() {
      return {
        sort: this.sort,
        sortDir: this.sortDir,
      }
    },
    fetchReviews() {
      const pagination = this.getPaginationPayload()
      const sorting = this.getSortingPayload()
      this.fetchDashboardsReviews({ dashboardId: this.dashboardId, pagination, sorting })
    },
    async onReviewsPageChange({ value }) {
      this.page = value
      this.fetchReviews()
    },
    async onReviewsSortChange({ value }) {
      const { sort, sortDir } = value
      this.sort = sort
      this.sortDir = sortDir
      this.fetchReviews()
    },
    async handleTrendsDateChange({ value }) {
      this.trendsSelectedDays = value
      const maxDate = this.$moment().format('YYYY-MM-DD')
      const minDate = this.$moment().subtract(value - 1, 'days').format('YYYY-MM-DD')
      this.fetchDashboardsTrends({ dashboardId: this.dashboardId, minDate, maxDate })
    },
    async fetchData() {
      await this.fetchDashboardsData({ dashboardId: this.dashboardId })
    },
    async onChangeTrendingPeriodDate({ value }) {
      const { dateRange, period } = value

      this.setDashboardFilters({
        trendingDataDateRangeFilter: {
          [period]: { ...dateRange },
        },
      })
    },
    onClickSentiment({ value }) {
      this.setDashboardFilters({ sentiment: value })
    },
    onClickMapCountry({ value }) {
      const { clickedCountry } = value
      this.setDashboardFilters({ countries: [clickedCountry] })
    },
    onClickRating({ value }) {
      this.setDashboardFilters({ ratings: [value] })
    },
    onClickTimeLineChartPoint({ value }) {
      this.setDashboardFilters({ dateRange: value })
    },
    onClickHostname({ value }) {
      this.setDashboardFilters({ hostnames: [value] })
    },
    onClickLocationEntity({ value: { label, entitySentiment } }) {
      this.setDashboardFilters({ countries: [label], sentiment: entitySentiment })
    },
    onClickEntitySentiment({ value }) {
      this.setDashboardFilters({ entitySentiment: value })
    },
    onClickEntity({ value }) {
      const { label, entitySentiment, entityGroupId } = value
      if (entityGroupId) {
        this.setDashboardFilters({ entityGroupId, entitySentiment })
      } else {
        this.setDashboardFilters({ entity: label, entitySentiment })
      }
    },
    setDimensions() {
      this.setBarchartWidth()
    },
  },
}
</script>

<style scoped>
.dashboardcontent {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.multiproductdashboard--tabs {
  margin-top: 16px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--r-border-color);
}
.multiproductdashboard--filter-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.multiproductdashboard--summary--box {
  border: 1px solid var(--r-border-color);
  height: 702px;
  overflow: hidden;
  border-radius: 3px;
}
.rcomponentheader--bordered {
  border-bottom: 1px solid var(--r-border-color);
  padding-bottom: 8px;
  margin-bottom: 8px;
}
/deep/ .v-skeleton-loader__image {
  height: 1000px;
}
/deep/ .multiproductdashboard--tabs .v-skeleton-loader__button  {
  height: 28px;
}
/deep/ .multiproductdashboard--filter-row .v-skeleton-loader__button,
/deep/ .multiproductdashboard--filter-row .v-skeleton-loader__chip {
  height: 24px;
}
.reviewscardsection {
  background-color: white;
  width: 25%;
}
.multiproductdashboard--date-range > div {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}
.multiproductdashboard--date-range i {
  font-weight: 700;
  margin-left: 10px;
}
.position-relative {
  position: relative;
}
/deep/ .content-wrapper {
  margin-top: -46px
}

</style>
