var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-modal', {
    staticClass: "editentitygroupmodal",
    attrs: {
      "title": _vm.modalTitle,
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": "Add to entity map",
      "fetching": _vm.fetching,
      "action-disabled": !_vm.localEntities.length,
      "error-message": _vm.errorMessage
    },
    on: {
      "close": _vm.onClickClose,
      "submit": _vm.onClickSubmit
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('p', {
          staticClass: "mb-2"
        }, [_vm._v(" Entity ")]), _vm.createNewGroup ? _c('r-text-field', {
          ref: "newgroupnamefield",
          attrs: {
            "value": _vm.newGroupName
          },
          on: {
            "change": function (_ref) {
              var value = _ref.value;
              return _vm.newGroupName = value;
            }
          }
        }) : _c('r-select', {
          staticClass: "editentitygroupmodal--selectgroup",
          attrs: {
            "label": "Entity",
            "items": _vm.entityMapGroupItems(_vm.activeGroupId),
            "append-items": [{
              label: 'Create a new entity',
              value: '_____NEW_ENTITY_____'
            }],
            "allow-empty": false
          },
          on: {
            "click:append-item": _vm.onClickCreateNewEntity,
            "change": _vm.onChangeSelectedEntityGroup
          }
        }), _c('p', {
          staticClass: "mt-4 mb-2"
        }, [_vm.selectedGroupName ? _c('b', [_vm._v(_vm._s(_vm.selectedGroupName))]) : _c('span', [_vm._v("The new entity group")]), _vm._v(" will be "), _vm.createNewGroup ? _c('span', [_vm._v("created, containing")]) : _c('span', [_vm._v("updated to contain")]), _vm._v(" the words ")]), _c('r-text-chips', {
          staticClass: "pa-2 editentitygroupmodal--textchips",
          attrs: {
            "chips": _vm.localEntities,
            "disabled": _vm.localEntities.length >= _vm.ENTITYMAPS_LIMITS.ENTITIES_PER_GROUP
          },
          on: {
            "add-chip": _vm.onAddEntity,
            "remove-chip": _vm.onRemoveEntity
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }